import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import TaskAssignMembers from "./TaskAssignMembers";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import { removeDuplicates } from "../../helpers/HelperMethods";
import attachmentIcon from "../../resources/Icons/Icon - Attachments.svg";
import removeAttachment from "../../resources/Icons/Icon - Remove Attachment.svg";
import calendarIcon from "../../resources/Icons/Icon - calender.svg";
import DatePicker from "react-datepicker";
import Dropdown from "../dropdown/dropdown";
import { getAISettingsForTask } from "../../util/general-helpers";
import { AI_TASK_CREATION } from "../../util/const-helpers";

const TaskReassign = ({
  setPage,
  taskId,
  taskName,
  createdById,
  taskTitle = "Assigned Members",
  isEditTask = false,
  errorFromEdit,
  setEditTaskMembers,
  setEditTaskOwner,
  task,
  currUserId
}) => {
  const context = useContext(AppContext);
  const [taskMembers, setTaskMembers] = useState([]);
  const [taskOtherMembers, setTaskOtherMembers] = useState([])
  const [projectInfo, setProjectInfo] = useState(null);
  const [intMemberIds, setIntMemberIds] = useState([]);
  const [permissionTaskMembers, setPermissionTaskMembers] = useState([]);
  const [taskAssigned, setTaskAssigned] = useState(false);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [assignedOwner, setAssignedOwner] = useState({});
  const [assignedOtherDepMembers, setAssignedOtherDepMembers] = useState([]);
  const [filterAssignedMembers, setFilterAssignedMembers] = useState([]);
  const [filteredOtherDepMembers, setFilteredOtherDepMembers] = useState([]);
  const [hide, setHide] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [comment, setComment] = useState("");
  const [priority, setPriority] = useState(task?.Priority);
  const [due, setDue] = useState(task?.Due);
  const [errorMessage, setErrorMessage] = useState([]);
  const [ownerAsAGroup, setOwnerAsAGroup] = useState(null);
  const[lockedValues, setLockedValues] = useState({
    taskApprover: false,
    taskPriority: false,
  });

  const setAiTaskConfigOnPageLoad = () => {
    let settings = context.aiConfig.find(s => s.Name === AI_TASK_CREATION);
    let taskConfig = getAISettingsForTask(context.currUser.CompanyId, settings);
    
    if (taskConfig) {
      setLockedValues({
        taskApprover: true,
        taskPriority: true,
      });
    } 
  }
  
  useEffect(() => {
    getAllTaskMembers();
    getProjectAdd();
    getGroupOwner();
    setAiTaskConfigOnPageLoad();
  }, []);

  useEffect(() => {
    if ((taskMembers?.length  || ownerAsAGroup!=null) && !taskAssigned) {
      getAllAssignedMembers(taskMembers, taskOtherMembers);
    }
  }, [taskMembers,taskOtherMembers, taskAssigned,ownerAsAGroup]);

  const getGroupOwner = () => {
    let temp = task.Groups?.filter(group => {
      if(group.IsOwner){
        return group;
      }
    })
    if(temp?.length > 0){
      setOwnerAsAGroup(temp[0]);
    }
  };

  const getAllTaskMembers = async() => {
    if (context.currentTask.Members?.length || context.currentTask.Groups?.length) {
      await ApiService.getAllDepUsers(context).then(
        async result => {
          if (result.status === 200) {
            let allDepMembers=[];

            result.data.forEach((member)=>{
              let newMembers = {UserId : member.Id, User: member};
              allDepMembers.push(newMembers);
            });
            let projectMembers = allDepMembers?.concat(context.currentTask.Members);
            let initialMemberList = removeDuplicates(projectMembers, "UserId");
            setTaskMembers(initialMemberList);  

          } else {
            console.log("API [GetProject] error status: " + result.status);
            setPage("500 error");
          }
        },
        error => {
          console.log("API [GetProject] error ->", error);
          setPage("500 error");
        }
      );
    }
  };

  const getProjectAdd = async () => {
    await ApiService.getProjectWithDep(task.ProjectId, context).then(
      async result => {
        if (result.status === 200) {
          console.log("Project was added in reassign", result.data);
          setProjectInfo(result.data);
          setTaskOtherMembers(result.data.DepMembers);
        } else {
          console.log("API [GetProject] error status: " + result.status);
          setPage("500 error");
        }
      },
      error => {
        console.log("API [GetProject] error ->", error);
        setPage("500 error");
      }
    );
  };

  const onSubmitAllReassignUpdates = async () => {
    const allProjMembers = projectInfo?.Members;
    const addedNewMembers = intMemberIds.filter(i => !allProjMembers.find(member => member.UserId === i));
    const stringMemberIds = addedNewMembers.toString();

    if (addedNewMembers.length > 0) {
      await ApiService.addMembersToProject(task.ProjectId, stringMemberIds, context)
        .then(res => {
          if (res.status == 200) {
            setIntMemberIds([]);
          }
        })
        .catch(error => {
          console.log("Error adding other Member =>");
          console.log(error);
        })
        .finally(() => onSubmitReassigned());
    } else {
      onSubmitReassigned();
    }
  };

  const getAllAssignedMembers = (projMembers, otherProjMembers) =>{
    let taskOwner = {};
    let currMembers = [];
    let otherMembers = [];
    let filterMembersNames = [];
    let otherFilterMembersNames = [];
    let permissionMembers = [];

    projMembers.forEach(function (member) {
      if (member.UserId === createdById) {
        taskOwner = member.User;
      }
      filterMembersNames.push(member.User.Name);
      currMembers.push(member.User);
      permissionMembers.push({
        Id: member.UserId,
        canApprove: member.CanApprove,
        canEdit: member.CanEdit || member.IsAssigned,
        canReassign: member.CanReassign,
        canView: member.CanView
      });
    });

    otherProjMembers.forEach(function (member) {
      otherFilterMembersNames.push(member.Name);
      otherMembers.push(member);
    });

    let uniqueMembers = removeDuplicates(currMembers, "Id");
    let uniqueOtherMembers = removeDuplicates(otherMembers, "Id");
    let uniquePermissions = removeDuplicates(permissionMembers, "Id");

    setAssignedOwner(taskOwner);
    if(isEditTask) {
      setEditTaskOwner(taskOwner);
    }
    setAssignedMembers([...uniqueMembers]);
    setAssignedOtherDepMembers([...uniqueOtherMembers]);
    setPermissionTaskMembers([...uniquePermissions]);
    setFilterAssignedMembers([...new Set(filterMembersNames)]);
    setFilteredOtherDepMembers([...new Set(otherFilterMembersNames)]);
  }

  // returns permission of user
  const userPermission = member => {
    if (member.CanView) {
      return "View";
    } else if (member.CanEdit || member.IsAssigned) {
      return "Edit";
    } else if (member.CanApprove) {
      return "Approve";
    } else {
      return "None";
    }
  };

  const addMembersInForm = (permission, id) => {
    setErrorMessage("");
    const loweredPermission = permission.toLowerCase();
    const addedMembersId = [...intMemberIds];

    let updatedTaskMembers = taskMembers.map((member) => {
      if (member.UserId == id) {
        addedMembersId.push(id);
        return {
          ...member,
          CanApprove: loweredPermission == "approver",
          CanEdit: loweredPermission == "editor",
          IsAssigned: loweredPermission == "editor",
          CanView: loweredPermission == "viewer"
        };
      } else {
        return member;
      }
    });

    if (isEditTask) {
      setEditTaskMembers(updatedTaskMembers, addedMembersId);
    }
    setIntMemberIds(addedMembersId);
    setTaskMembers(updatedTaskMembers);
    setTaskAssigned(true);
  };

  const removeMembersInForm = (permission, id) => {
    const updatedMembers = taskMembers.filter(m => m.UserId !== id);
    const updatedTaskUsers = intMemberIds.filter(userId => userId !== id);
    const removedMember = taskMembers.filter(m => m.UserId == id);

    if (permission === "editor") {
      removedMember[0].CanEdit = false;
      removedMember[0].IsAssigned = false;
    } else if (permission === "viewer") {
      removedMember[0].CanView = false;
    } else if (permission === "approver") {
      removedMember[0].CanApprove = false;
    }
    updatedMembers.push(removedMember[0]);

    if(isEditTask){
      setEditTaskMembers(updatedMembers, updatedTaskUsers);
    }
    setTaskMembers(updatedMembers);
    setIntMemberIds(updatedTaskUsers);
    setErrorMessage("");
  };

  // hides component body when loading overlay is displayed (security purposes, prevents inspect element to remove overlay)
  const hideContent = hidden => {
    if (hide != hidden) {
      setHide(hidden);
    }
  };

  const onBrowseFilesLoaded = convertedFiles => {
    let files = selectedFiles;

    if (files.length === 0) {
      files = convertedFiles;
    } else {
      let fileFound = false;
      convertedFiles.forEach(file => {
        for (let matchingFile of files) {
          if (matchingFile.name === file.name) {
            fileFound = true;
            break;
          }
        }
        if (!fileFound) {
          files.push(file);
        }
      });
    }
    setSelectedFiles([...files]);
  };

  const browseFile = e => {
    e.preventDefault();
    e.stopPropagation();

    let newSelectedFiles = Array.from(e.target.files);
    e.target.value = null;

    filesToBase64(newSelectedFiles);
  };

  const deleteBrowseFile = (e, name) => {
    e.preventDefault();
    e.stopPropagation();
    let files = selectedFiles;
    let idx = files.findIndex(file => file.name === name);
    files.splice(idx, 1);
    setSelectedFiles([...files]);
  };

  const filesToBase64 = files => {
    let convertedFiles = [];
    let loadedFilesCount = 0;

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        convertedFiles = [
          ...convertedFiles,
          { name: file.name, TaskId: taskId, FileName: file.name, FileContentType: file.type, Base64String: base64String }
        ];
        loadedFilesCount++;

        if (loadedFilesCount === files.length) {
          onBrowseFilesLoaded(convertedFiles);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onSubmitReassigned = () => {
    let reassignRequest = {
      Id: context.currentTask.Id,
      allApprovers: context.currentTask.AllApprovers,
      // Members: taskMembers,
      Comments: [
        { TaskId: context.currentTask.Id, Note: comment, ActionId: "5", Files: selectedFiles }
      ],
      taskType: context.currentTask.TaskType,
      Priority: priority,
      Due: due
    };
    let hasEditor = false;
    let ownerHasPermission = false;
    taskMembers.forEach(member => {
        if(member.User.Id === assignedOwner.Id){
          if(member.CanView || member.CanApprove || member.CanEdit || member.IsAssigned)
            ownerHasPermission = true;
        }

        if(member.IsAssigned || member.CanEdit){
          hasEditor = true;
        }
    });
    if(!hasEditor && ownerHasPermission && ownerAsAGroup == null){
      setErrorMessage("Unable to Re-Assign Task: Must have at least 1 editor");
      return;
    }
    else if(!hasEditor && !ownerHasPermission && ownerAsAGroup == null){
      taskMembers.forEach(m => {
        if(m.User.Id === assignedOwner.Id){
          m.CanEdit = true;
          m.IsAssigned = true;
        }
      });
    }

    reassignRequest.Members = taskMembers.filter(m => (m.UserId === assignedOwner.Id && ownerAsAGroup == null) || m.CanView || m.CanApprove || m.CanEdit || m.IsAssigned);

    ApiService.ReassignTask(reassignRequest, context)
      .then(result => {
        if (result.status === 200) {
          setPage("taskReassigned");
        } else {
          console.log("API [ReassignTask] error status", result.status);
          setPage("500 error");
        }
      })
      .catch(e => {
        console.log("API [ReassignTask] error ->", e);
        setPage("500 error");
      });
  };

  const errorMessageDisplay = (message) => {
    return (
      message !== "" && (
        <Form.Label>
          <div type="invalid" className="error-message-assign">
            {message}
          </div>
        </Form.Label>
      )
    );
  };

  const reassignedMembersTable = (
    <React.Fragment>
      <Form.Group data-testid="selectedMembers">
        <Form.Label className="loginInput appFontSubHeading">
          {taskTitle}<i className="red">*</i>
        </Form.Label>
        <TaskAssignMembers
          assignedMembers={assignedOtherDepMembers}
          assignedOwner={assignedOwner}
          filteredAssignedMembers={filteredOtherDepMembers}
          addMembersInForm={addMembersInForm}
          removeMembersInForm={removeMembersInForm}
          permissionTaskMembers={permissionTaskMembers}
          task={task}
          currUserId={currUserId}
          ownerGroup={ownerAsAGroup}
          isDisableApprover={lockedValues.taskApprover}
        />
        {isEditTask ? (
          errorMessageDisplay(errorFromEdit)
        ) : (
          errorMessageDisplay(errorMessage)
        )}
      </Form.Group>
    </React.Fragment>
  );

  const reassignedComments = (
    <React.Fragment>
      <Form.Group className="mt-1 mb-1">
        <Form.Label className="loginInput appFontSubHeading">Comments</Form.Label>
        <Form.Control
          className="form-control form-custom-control shadow-none projectDescription"
          as="textarea"
          id="commentNote"
          name="commentNote"
          rows="5"
          maxLength={2000}
          value={comment}
          onChange={e => setComment(e.target.value)}
          placeholder="Enter comment"
        />
      </Form.Group>
      <Form.Group>
        <div className="project-attachment">
          <div className="image-upload w-10">
            <div className="file-input-btn">
              <label htmlFor="file-input" className="w-10">
                <img className="pointer" src={attachmentIcon} alt="Attachment" />
              </label>
            </div>
            {selectedFiles.map(file => {
              return (
                <div className="file-pill" key={file.name}>
                  <div className="fileAttachment w-10">
                    <OverlayTrigger
                      placement="auto-start"
                      delay={{ show: 250, hide: 50 }}
                      overlay={
                        <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                          {file.name}
                        </Tooltip>
                      }
                    >
                      <span>{file.name}</span>
                    </OverlayTrigger>
                    <div className="w-1 float-right">
                      <img
                        src={removeAttachment}
                        className="pointer float-right"
                        alt="Remove Attachment"
                        onClick={e => deleteBrowseFile(e, file.name)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <input
              type="file"
              id="file-input"
              accept=".doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf, .xlsm"
              multiple
              onChange={e => browseFile(e)}
            />
          </div>
        </div>
      </Form.Group>
    </React.Fragment>
  );

  const reassignedDue = (
    <Form.Group>
      <Form.Label className="loginInput appFontSubHeading">
        Due Date <i className="red">*</i>
      </Form.Label>
      <DatePicker
        className={`form-control form-custom-control shadow-none`}
        id="taskDue"
        name="taskDue"
        selected={due}
        onChange={value => {
          value.setUTCHours(23, 59, 59, 0);
          setDue(value);
        }}
        minDate={new Date()}
        maxDate={Date.parse(task?.Project?.End) ?? new Date()}
        placeholderText="MM/DD/YYYY"
        autoComplete="off"
      />
      <img className="calendar-icon" src={calendarIcon} alt="Calendar Icon" />
    </Form.Group>
  );

  const reassignedPriority = (
    <Form.Group id="taskPriority">
    <Form.Label className="loginInput appFontSubHeading">
      Priority <i className="red">*</i>
    </Form.Label>
    <Dropdown
      values={task?.PrioritiesAvailable.map(p => {
        return { id: p, name: p };
      })}
      dropdownButtonText={"Select an Option"}
      onSelect={selectedValue => {
        setPriority(selectedValue.id);
      }}
      currentSelected={priority}
      disabled={lockedValues.taskPriority}
    />
  </Form.Group>
  );

  const reassignedButtons = (
    <React.Fragment>
      <div style={{ paddingBottom: "140px" }} />
      <div className="fixed-bottom pl-3 pr-3 pt-4 pb-4 whiteBackground">
        <button
          className="loginButton btn-primary blueButtonOutline button50 shadow-none"
          onClick={() => setPage("taskEditor")}
        >
          Cancel
        </button>
        <button
          className="loginButton btn-primary blueButton button50 shadow-none float-right"
          onClick={() => {
            onSubmitAllReassignUpdates();
          }}
        >
          Re-Assign Task
        </button>
      </div>
    </React.Fragment>
  );

  const showReassignedMembersContent = () => {
    let reassignedContent = null;
    if (!isEditTask) {
      reassignedContent = (
        <div className="mt-7 mr-3">
          <div className="row">
            <div className="col-12 ml-2 mr-2 appFont reassignPageTitle">Re-Assign Members</div>
          </div>
          <div className="row">
            <div className="col-12 ml-2 mr-2 reassignTaskName">{taskName}</div>
          </div>
          <div className="row">
            <div className="col-12 ml-2 mr-2">{reassignedDue}</div>
          </div>
          <div className="row">
            <div className="col-12 ml-2 mr-2">{reassignedPriority}</div>
          </div>
          <div className="row">
            <div className="col-12 ml-2 mr-2">{reassignedMembersTable}</div>
          </div>
          <div className="row">
            <div className="col-12 ml-2 mr-2">{reassignedComments}</div>
          </div>
          {reassignedButtons}
        </div>
      );
    } else {
      reassignedContent = (
        <React.Fragment>
          {reassignedMembersTable}
        </React.Fragment>
      )
    }

    return reassignedContent;
  };

  return (
    <div>
      <LoadingOverlay area="task-reassign-area" inline="loading-overlay-inline" hideContent={hideContent} />
      { !hide &&
        <React.Fragment>
          {showReassignedMembersContent()}
        </React.Fragment>
      }
    </div>
  );
};

export default TaskReassign;
