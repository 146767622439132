import React, { useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "../../../app/AppContext";

export default function ChatMessageUser({ message }) {
  const context = useContext(AppContext);

  return (
    <div className="d-flex align-items-start chat-conversation-row bg-light">
      <div className="chat-conversation-user">
        {context?.userNameFull
          .split(" ")
          .map(str => str[0])
          .join("")
          .toUpperCase()}
      </div>
      <div className="chat-conversation-row-text">{message}</div>
    </div>
  );
}

ChatMessageUser.propTypes = {
  message: PropTypes.string.isRequired
};
