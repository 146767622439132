import React from "react";
import PropTypes from "prop-types";
import ai from "../../../../assets/triyo-ai-icon.svg";

export default function ChatMessageAssistant({ message }) {
  const theObj = { __html: message };

  return (
    <div className="d-flex align-items-start chat-conversation-row">
      <div className="chat-conversation-assistant">
        <img src={ai} alt="Triyo_AI" />
      </div>
      <div className="chat-conversation-row-text" dangerouslySetInnerHTML={theObj}></div>
    </div>
  );
}

ChatMessageAssistant.propTypes = {
  message: PropTypes.string.isRequired
};
