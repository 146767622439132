import React from "react";
import PropTypes from 'prop-types';
import FluentUiIcon from '../../../icons/fluent-ui-icon';

export default function ChatHeader({ setShowForm }) {
  return (
    <>
      <div role="button" onClick={() => setShowForm((prev) => !prev)}>
        <FluentUiIcon name={"ChevronLeftRegular"} color={"#000"} size={20} />
      </div>
      <div className="chat-header-text pl-2">TRIYO AI Assistant</div>
    </>
  );
}

ChatHeader.propTypes = {
  setShowForm: PropTypes.func.isRequired,
};