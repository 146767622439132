import ApiService from "../services/ApiService";
import { AI_DEFAULT_TASK_CREATION, AI_TASK_CREATION } from "./const-helpers";

export const scrollToBottomById = Id => {
  setTimeout(() => {
    let element = document.getElementById(Id);
    if (element) element.scrollTop = element.scrollHeight;
  }, 100);
};

export const getAISettings = async context => {
  let aiSettings = await ApiService.getSettings(context);
  return aiSettings.data.filter(s => s.Name === AI_TASK_CREATION || s.Name === AI_DEFAULT_TASK_CREATION);
};

export const getAISettingsForTask = (companyId, setting) => {
  let result = null;
  if (!setting) return result;

  //if (taskTypeId === 0) {
  let splitValue = setting?.Value?.split(":");
  if (companyId === setting.CompanyId && setting.Value !== "0") {
    result = splitValue;
  }
  //   }
  // } else {
  //   let taskTypeSplit = setting?.Value?.split(",");
  //   if (taskTypeSplit.includes(taskTypeId.toString()) && setting.Value !== "0") {
  //     result = splitValue;
  //   }
  // }
  return result;
};

export const isAIAvailable = context => {
  let aiConfig = context.aiConfig;
  if (!aiConfig || aiConfig?.length == 0) return false;

  let taskTypeId = context.currentTask.WorkItemTypeId;
  let companyId = context.currUser.CompanyId;
  
  let aiCreate = aiConfig?.find(ai => ai.Name === AI_TASK_CREATION);
  let aiCreateCompany = aiCreate.CompanyId;
  let aiCreateTaskType = aiCreate?.Value?.split(":")[1];

  let aiCreateDefaultTaskType = aiConfig?.find(ai => ai.Name === AI_DEFAULT_TASK_CREATION)?.Value?.split(",");
  
  let taskTypeLockedCondition =
    aiCreateTaskType && aiCreateTaskType === taskTypeId.toString() && aiCreateCompany === companyId;
  let defaultTaskTypeCondition = aiCreateDefaultTaskType && aiCreateDefaultTaskType?.includes(taskTypeId.toString());

  return taskTypeLockedCondition || defaultTaskTypeCondition;
};
