import React, { Component } from "react";
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";
import Moment from "react-moment";
import { convertUTCDateToLocalDate } from "../../helpers/HelperMethods";
import TaskPriority from "./TaskPriority";
import TaskMembersPopover from "./TaskMembersPopover";
import TaskStatus from "./TaskStatus";
import LastActivity from "./TaskLastActivity";
import TaskReminders from "./TaskReminders";
import TaskRemindersHeader from "./TaskRemindersHeader";
import AppContext from "../../app/AppContext";
import ApiService from "../../services/ApiService";
import OfficeWordService from "../../services/OfficeWordService";
import { trackPromise } from "react-promise-tracker";
import LoadingOverlay from "../loading-overlay/loading-overlay";
import assignedContentIcon from "../../resources/Icons/Icon - Assigned Content.svg";
import changedContentIcon from "../../resources/Icons/Icon - Changed Content.svg";
import originalDocumentIcon from "../../resources/Icons/Icon - Original Document.svg";
import updatedDocumentIcon from "../../resources/Icons/Icon - Updated Document.svg";
import liveDocumentIcon from "../../resources/Icons/Icon - Live Document.svg";
import reassignIcon from "../../resources/Icons/Icon - Reassign.svg";
import completeIcon from "../../resources/Icons/Icon - Check.svg";
import completeSuccessIcon from "../../resources/Icons/Custom-Successfull-16px-Green.svg";
import completeUnsuccessfulIcon from "../../resources/Icons/Custom-Unsuccessful-16px-Yellow.svg";
import businessEscalationIcon from "../../resources/Icons/Custom-BusinessEscalation-16px-Blue.svg";
import grantExtensionIcon from "../../resources/Icons/Custom-GrantExtension-16px-Blue.svg";
import followUpIcon from "../../resources/Icons/Icon - Send.svg";
import approveIcon from "../../resources/Icons/icon_Thumbs_Up_16px.svg";
import declineIcon from "../../resources/Icons/icon_Thumbs_Down_16px.svg";
import requestReviewIcon from "../../resources/Icons/icon_Request_Review_16px.svg";
import editIcon from "../../resources/Icons/Icon - Edit.svg";
import actionsIcon from "../../resources/Icons/Icon - Action.svg";
import cancelIcon from "../../resources/Icons/Close-16px-Blue-60.svg";
import downloadIcon from "../../resources/Icons/Download-20px-Blue-60.svg";
import TasksDynamicFieldsContent from "../dynamicFields/ContentViewer/TasksDynamicFieldsContent";
import { notificationRed } from "../../resources/Icons/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "../../css/globals.scss";
import { addAuditTrail } from "./TaskDetailsHeaderUtil";
import InlineNotification from "../inline-notification/inline-notification";
import usingComputer from "../../resources/Images/Image - Using Computer.svg";
import FluentUiIcon from "../../icons/fluent-ui-icon";
import { isAIAvailable } from "../../util/general-helpers";

const CustomRow = props => {
  const { name, children, overlay, onClick, workflow, imagesPreview } = props;
  const rowStyling = name.toLowerCase() === "status" ? "statusBadgeRow": name == "Last Activity" ? "statusBadgeRow" : ""
  return (
    <div className="row ml-2 mr-1">
      <div className="col-12 taskDetailsRow appFont">
        <div className={`float-left taskDetailsLabel ${rowStyling}`}>{name}</div>
        { overlay ? (
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 250 }}
            overlay={<Tooltip id="tooltip-1">{overlay}</Tooltip>}
          >
            <div
              className={
                "float-right text-right mr-2 taskDetailsText taskDescription " +
                (name == "Task Type" ? "taskManagementType" : "")
              }
            >
              {children}
            </div>
          </OverlayTrigger>
        ) : (
          <div
            className={
              `float-right text-right mr-2 taskDetailsText ${rowStyling}  ${name == "Task Type" ? "taskManagementType" : ""}
            `}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

class TaskDetailsHeader extends Component {
  state = {
    pageNumber: 1,
    previewMode: "",
    hide: false,
    hideView: false,
    currUserId: 0,
    loading: this.context.officeAppType == "Outlook" ? true : false,
    getTaskRemindersCompleted: false,
    taskReminders: null,
    showChanges: true,
    customStatusPermission: false,
    expandReminders: false,
    ownerGroup : [],
    showConfirm: false,
    linkSuccess: null,
  };

  static contextType = AppContext;

  componentDidMount = () => {
    this.getCurrentUser();
    if (this.context.officeAppType == "Outlook") {
      this.getTaskReminders();
    }
    if (this.props.task?.Groups?.length > 0) {
      this.props.task?.Groups.forEach(element => {
        if (element.IsOwner) {
          this.setState({ ownerGroup: element });
        }
      });
    }
  };

  setPreviewMode = mode => {
    console.log("Peview Mode -> " + mode);
    this.setState({ previewMode: mode });
  };

  getCurrentUser = () => {
    trackPromise(
      ApiService.getCurrentUser(this.context)
        .then(result => {
          if (result.status === 200) {
            this.setState({ currUserId: result.data.UserId });
            this.hasCustomStatusPermission(result.data.UserId);
          } else {
            console.log("API [GetCurrentUser] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetCurrentUser] error ->");
          console.log(e);
          this.props.setAppPage("500 error");
        }),
      "task-details-header-area"
    );
  };

  getTaskReminders = () => {
    trackPromise(
      ApiService.getTaskReminders(this.props.task.Id, this.context)
        .then(result => {
          if (result.status === 200) {
            const taskRemindersResults = result.data;
            this.setState({
              taskReminders: taskRemindersResults,
              getTaskRemindersCompleted: true
            });
          } else {
            console.log("API [getTaskReminders] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [getTaskReminders] error ->");
          console.log(e);
          this.props.setPage("500 error");
        }),
      "task-details-header-area"
    );
  };

  getTaskDocumentPowerpoint = id => {
    trackPromise(
      ApiService.getTaskDocumentPowerpoint(id, this.context)
        .then(result => {
          if (result.status === 200) {
            PowerPoint.createPresentation(result.data);
          } else {
            console.log("API [GetTaskDocumentPowerpoint] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetTaskDocumentPowerpoint] error ->");
          console.log(e);
          this.props.setAppPage("500 error");
        }),
      "task-details-header-area"
    );
  };

  getTaskDocumentWord = id => {
    trackPromise(
      ApiService.getTaskDocumentWord(id, this.context)
        .then(result => {
          if (result.status === 200) {
            OfficeWordService.createDocument(result.data);
          } else {
            console.log("API [GetTaskDocumentWord] error status: " + result.status);
          }
        })
        .catch(e => {
          console.log("API [GetTaskDocumentWord] error ->");
          console.log(e);
          this.props.setAppPage("500 error");
        }),
      "task-details-header-area"
    );
  };

  hideContent = hidden => {
    if (this.state.hide != hidden) {
      this.setState({ hide: hidden });
    }
  };

  hideDocumentContent = hidden => {
    if (this.state.hide !== hidden) {
      this.setState({ hide: hidden });
    }
  };

  cancel = () => {
    return (
      <button
        onClick={() => this.setPreviewMode("")}
        style={{ border: "none", background: "none", padding: 0 }}
      >
        <img
          src={cancelIcon}
          className="pointer"
          alt="Cancel Button"
        />
      </button>
    );
  };

  getUniqueFileName = length => {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  downloadContent = task => {
    let uniqueFileName = this.getUniqueFileName(10);

    if (this.state.previewMode === "projectUpdated" || this.state.previewMode === "liveDocument") {
      if (this.props.task.DocumentType === "Word") {
        const fd = new FormData();
        let docType = this.state.previewMode === "projectUpdated" ? "Updated" : "Live";
        fd.append("docType", docType);
        trackPromise(
          ApiService.downloadOriginalWordDocument(task.Id, fd, this.context).then(async result => {
            if (result.status === 200) {
              const blob = await (
                await fetch(
                  `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${result.data}`
                )
              ).blob();
              saveAs(blob, task.Document.FileName);
            } else {
              console.log("API [downloadContent] error status: " + result.status);
            }
          })
        );
      } else if (this.props.task.DocumentType === "PowerPoint") {
        const fd = new FormData();
        fd.append("docType", "Updated");
        fd.append("isWeb", "false");
        trackPromise(
          ApiService.downloadOriginalPPTDocument(task.Id, fd, this.context).then(async result => {
            if (result.status === 200) {
              const blob = await (
                await fetch(
                  `data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${result.data}`
                )
              ).blob();
              saveAs(blob, task.Document.FileName);
            } else {
              console.log("API [downloadContent] error status: " + result.status);
            }
          })
        );
      }
    } else if (this.state.previewMode === "original") {
      if (this.props.task.DocumentType === "Word") {
        trackPromise(
          ApiService.getTaskDocumentWord(task.WordDocumentFileId, this.context).then(async result => {
            if (result.status === 200) {
              const blob = await (
                await fetch(
                  `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${result.data}`
                )
              ).blob();
              saveAs(blob, task.WordDocumentFile);
            } else {
              console.log("API [downloadTaskDocumentWord] error status: " + result.status);
            }
          })
        );
      } else if (this.props.task.DocumentType === "PowerPoint") {
        trackPromise(
          ApiService.getTaskDocumentPowerpoint(task.WordDocumentFileId, this.context, task?.StatusId).then(
            async result => {
              if (result.status === 200) {
                const blob = await (
                  await fetch(
                    `data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${result.data}`
                  )
                ).blob();
                saveAs(blob, uniqueFileName);
              } else {
                console.log("API [DownloadTaskDocumentPowerPoint] error status: " + result.status);
              }
            }
          )
        );
      }
    }
  };

  customButtomHardcodedIcons = status => {
    if(status?.toLowerCase().includes("business escalation"))
        return businessEscalationIcon;
    else if(status == "Extension Granted")
        return grantExtensionIcon;
    else if(status == "Completed - Successfully" || status == "Outreach Complete")
      return completeSuccessIcon;
    else if(status == "Completed - Unsuccessfully" || status?.toLowerCase().includes("quarantine"))
        return completeUnsuccessfulIcon;
    else if(status?.toLowerCase().includes("follow"))
        return followUpIcon;
    else
        return completeIcon;
  };

  hasCustomStatusPermission = userId => {
    if (this.props.task.CreatedById == userId) this.setState({ customStatusPermission: true });
    else if (this.props.task?.StatusAndButtons?.Status == "Pending Approval" && this.props.task.Permission == "Approve")
      this.setState({ customStatusPermission: true });
    else if (this.props.task.Permission == "Edit") this.setState({ customStatusPermission: true });
    else if (this.props.task.Permission == "Owner" || this.state.ownerGroup?.GroupId) this.setState({ customStatusPermission: true });
    else this.setState({ customStatusPermission: false });
  };

  taskActions = customStatuses => {
    const linkButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Link Email to Task</Tooltip>}
      >
        <a
          className="dropdown-item appFont taskOptions"
          href="#"
          onClick={() =>
            this.setState(prevState => {
              return {
                ...prevState,
                showConfirm: !prevState.showConfirm
              };
            })
          }
        >
          <FluentUiIcon name={"MailFilled"} color={"#0F6CBD"} size={16} />
          &nbsp;&nbsp;&nbsp;Link Email to Task
        </a>
      </OverlayTrigger>
    );

    const completeButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Complete Task</Tooltip>}
      >
        <a className="dropdown-item appFont taskOptions" href="#" onClick={this.props.onClick}>
          <img src={completeIcon} className="task-icons" alt="Complete Task" />
          &nbsp;&nbsp;&nbsp;Complete Task
        </a>
      </OverlayTrigger>
    );

    const approveButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Approve Task</Tooltip>}
      >
        <a className="dropdown-item appFont taskOptions" href="#"
          onClick={() => this.props.onSetStatusClick(3, false)}>
          <img src={approveIcon} className="task-icons" alt="Approve Task" />
          &nbsp;&nbsp;&nbsp;Approve Task
        </a>
      </OverlayTrigger>
    );

    const declineButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Decline Task</Tooltip>}
      >
        <a className="dropdown-item appFont taskOptions" href="#"
          onClick={() => this.props.onSetStatusClick(4, false)}>
          <img src={declineIcon} className="task-icons" alt="Decline Task" />
          &nbsp;&nbsp;&nbsp;Decline Task
        </a>
      </OverlayTrigger>
    );

    const requestReviewButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Request Review</Tooltip>}
      >
        <a className="dropdown-item appFont taskOptions" href="#"
          onClick={() => this.props.onSetStatusClick(11, false)}>
          <img src={requestReviewIcon} className="task-icons" alt="Decline Task" />
          &nbsp;&nbsp;&nbsp;Request Review
        </a>
      </OverlayTrigger>
    );

    const cancelButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Cancel</Tooltip>}
      >
        <a className="dropdown-item appFont taskOptions" href="#"
          onClick={() => this.props.setAppPage("taskAction: Cancel")}>
          <img src={cancelIcon} className="task-icons" alt="Cancel Task" />
          &nbsp;&nbsp;&nbsp;Cancel
        </a>
      </OverlayTrigger>
    );

    const reassignButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Re-Assign</Tooltip>}
      >
        <a className="dropdown-item appFont taskOptions" href="#"
          onClick={() => this.props.setAppPage("taskReassign")}>
          <img src={reassignIcon} className="task-icons" alt="Re-Assign Task" />
          &nbsp;&nbsp;&nbsp;Re-Assign
        </a>
      </OverlayTrigger>
    );

    const remindButtonElement = (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        delay={{ show: 150, hide: 150 }}
        overlay={<Tooltip id="tooltip-1">Reminder</Tooltip>}
      >
        <a className="dropdown-item appFont taskOptions" href="#"
          onClick={() => this.props.setAppPage("taskAction: Reminder")}>
          <img src={notificationRed} alt="Reminder" style={{ marginLeft: "3px" }}/>
          &nbsp;&nbsp;&nbsp;&nbsp;Reminder
        </a>
      </OverlayTrigger>
    );

    const setStatus = (
      <div>
        {customStatuses &&
          customStatuses.map(status => {
            return (
              <OverlayTrigger
                key={"Overlay" + status.Name}
                placement="bottom"
                flip={true}
                delay={{ show: 150, hide: 150 }}
                overlay={<Tooltip id="tooltip-1">{status.Name}</Tooltip>}
              >
                <a className="dropdown-item appFont taskOptions" href="#" onClick={() => this.props.onSetStatusClick(status.Id, true)}>
                  <img src={this.customButtomHardcodedIcons(status.Name)} className="task-icons" alt="Set Status" />
                  &nbsp;&nbsp;&nbsp;{status.Name}
                </a>
              </OverlayTrigger>
            );
          })}
        {customStatuses && <hr className="actionButtonSeperator" />}
      </div>
    );

    const taskFinished = this.props.task.StatusId === 2 || this.props.task.StatusId === 3 || this.props.task.ActionId === 8;
    if (taskFinished) {
      return (
        <a className="dropdown-item appFont taskOptions" style={{ cursor: "pointer" }}>
          No Actions
        </a>
      );
    }
    return this.props.workflow?.WorkflowButtons.includes("Complete") ||
      this.props.workflow?.WorkflowButtons.includes("Cancel") ||
      this.props.workflow?.WorkflowButtons.includes("Reminder") ||
      this.props.workflow?.WorkflowButtons.includes("Reassign") ||
      this.props.workflow?.WorkflowButtons.includes("Approve") ||
      this.props.workflow?.WorkflowButtons.includes("Request Review") ? (
      <React.Fragment>
        {this.state.customStatusPermission && setStatus}
        {this.props.workflow?.WorkflowButtons.includes("Approve") && approveButtonElement}
        {this.props.workflow?.WorkflowButtons.includes("Approve") && declineButtonElement}
        {this.props.workflow?.WorkflowButtons.includes("Request Review") && requestReviewButtonElement}
        {this.props.workflow?.WorkflowButtons.includes("Complete") && completeButtonElement}
        {this.props.workflow?.WorkflowButtons.includes("Cancel") && cancelButtonElement}

        {(this.props.workflow?.WorkflowButtons.includes("Complete") ||
          this.props.workflow?.WorkflowButtons.includes("Cancel") ||
          this.props.workflow?.WorkflowButtons.includes("Approve") ||
          this.props.workflow?.WorkflowButtons.includes("Request Review")) && <hr className="actionButtonSeperator" />}

        {this.props.workflow?.WorkflowButtons.includes("Reminder") && remindButtonElement}
        {this.props.workflow?.WorkflowButtons.includes("Reassign") && reassignButtonElement}
        {isAIAvailable(this.context) && linkButtonElement}
      </React.Fragment>
    ) : (
      <a className="dropdown-item appFont taskOptions" style={{ cursor: "pointer" }}>
        No Actions
      </a>
    );
  };

  render = () => {
    let activeView = null;
    let taskdocsView = null;
    let previewMode = null;
    let taskActions = this.taskActions(this.props.configurableWorkflow?.Statuses);

    if (this.props.task.DocumentType === "Word" || this.props.task.DocumentType === "PowerPoint") {
      if (this.state.previewMode == "original") {
        previewMode = (
          <>
            <CustomRow name="Assigned Document">
              <img src={downloadIcon} className="downloadAll" onClick={() => this.downloadContent(this.props.task)} />
              {this.cancel()}
              {this.props.task.DocumentType === "PowerPoint" ? (
                <LoadingOverlay
                  area="preview-mode"
                  inline="loading-overlay-inline-block"
                  hideContent={this.hideDocumentContent}
                />
              ) : (
                <LoadingOverlay
                  area="preview-mode"
                  inline="loading-overlay-inline-block"
                  loading={!this.props.WordDocumentPdfOriginalBase64}
                  minDuration={100}
                />
              )}
            </CustomRow>
            {!this.state.hideView &&
              this.props.task.DocumentType === "PowerPoint" &&
              this.props.imagesPreview.WordDocumentPdfOriginalBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
            {!this.state.hideView &&
              this.props.task.DocumentType === "Word" &&
              this.props.WordDocumentPdfOriginalBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
          </>
        );
      }

      if (this.state.previewMode == "updated") {
        previewMode = (
          <>
            <CustomRow name="Changed Document">
              {this.cancel()}
              {this.props.task.DocumentType === "PowerPoint" ? (
                <LoadingOverlay
                  area="preview-mode"
                  inline="loading-overlay-inline-block"
                  hideContent={this.hideDocumentContent}
                />
              ) : (
                <LoadingOverlay
                  area="preview-mode"
                  inline="loading-overlay-inline-block"
                  loading={!this.props.WordDocumentPdfUpdatedBase64}
                  minDuration={100}
                />
              )}
            </CustomRow>
            {!this.state.hideView &&
              this.props.task.DocumentType === "PowerPoint" &&
              this.props.imagesPreview.WordDocumentPdfUpdatedBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
            {!this.state.hideView &&
              this.props.task.DocumentType === "Word" &&
              this.props.WordDocumentPdfUpdatedBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
          </>
        );
      }

      if (this.state.previewMode == "liveDocument") {
        previewMode = (
          <span className="preview-mode">
            <CustomRow name="Live Document">
              <img src={downloadIcon} className="downloadAll" onClick={() => this.downloadContent(this.props.task)} />
              {this.cancel()}
            </CustomRow>
            {// This is the Hide Changes radio button
            !this.state.hideView && (
              <div className="switch-checkbox">
                <Form.Check
                  type="switch"
                  id="word-update-contecnt-switchbox"
                  checked={!this.state.showChanges}
                  onChange={() => {
                    this.setState(prevState => {
                      return {
                        ...prevState,
                        showChanges: !prevState.showChanges
                      };
                    });
                  }}
                />
                <h3 className="switch-checkbox-label">{!this.state.showChanges ? "Hide Changes" : "Show Changes"}</h3>
              </div>
            )}
            {
              <span className="row col-12">
                <div>
                  {this.props.task.DocumentType === "PowerPoint" ? (
                    <LoadingOverlay
                      area="preview-mode"
                      inline="loading-overlay-inline-blackline"
                      hideContent={this.hideDocumentContent}
                    />
                  ) : (
                    <LoadingOverlay
                      area="preview-mode"
                      inline="loading-overlay-inline-blackline"
                      loading={
                        !this.state.showChanges
                          ? !this.props.WordProjectDocumentPdfUpdatedBase64
                          : !this.props.WordDocumentBlacklinePdfUpdatedBase64
                      }
                      minDuration={100}
                    />
                  )}
                </div>
              </span>
            }
            {console.log(this.props)}
            {!this.state.hideView &&
              !this.state.showChanges &&
              this.props.task.DocumentType === "PowerPoint" &&
              this.props.imagesPreview.WordProjectDocumentPdfPreviewBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
            {!this.state.hideView &&
              !this.state.showChanges &&
              this.props.task.DocumentType === "Word" &&
              this.props.WordProjectDocumentPdfPreviewBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}

            {!this.state.hideView &&
              this.state.showChanges &&
              this.props.task.DocumentType === "PowerPoint" &&
              this.props.imagesPreview.WordProjectDocumentPdfOriginalBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}

            {!this.state.hideView &&
              this.state.showChanges &&
              this.props.task.DocumentType === "Word" &&
              this.props.WordDocumentBlacklinePdfUpdatedBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
          </span>
        );
      }

      if (this.state.previewMode == "projectUpdated") {
        previewMode = (
          <span className="preview-mode">
            <CustomRow name="Updated Document">
              <img src={downloadIcon} className="downloadAll" onClick={() => this.downloadContent(this.props.task)} />
              {this.cancel()}
            </CustomRow>
            {// This is the Hide Changes radio button
            !this.state.hideView && (
              <div className="switch-checkbox">
                <Form.Check
                  type="switch"
                  id="word-update-contecnt-switchbox"
                  checked={this.state.showChanges}
                  onChange={() => {
                    this.setState(prevState => {
                      return {
                        ...prevState,
                        showChanges: !prevState.showChanges
                      };
                    });
                  }}
                />
                <h3 className="switch-checkbox-label">{this.state.showChanges ? "Hide Changes" : "Show Changes"}</h3>
              </div>
            )}
            {
              <span className="row col-12">
                <div>
                  {this.props.task.DocumentType === "PowerPoint" ? (
                    <LoadingOverlay
                      area="preview-mode"
                      inline="loading-overlay-inline-blackline"
                      hideContent={this.hideDocumentContent}
                    />
                  ) : (
                    <LoadingOverlay
                      area="preview-mode"
                      inline="loading-overlay-inline-blackline"
                      loading={
                        !this.state.showChanges
                          ? !this.props.WordProjectDocumentPdfUpdatedBase64
                          : !this.props.WordDocumentBlacklinePdfUpdatedBase64
                      }
                      minDuration={100}
                    />
                  )}
                </div>
              </span>
            }
            {!this.state.hideView &&
              !this.state.showChanges &&
              this.props.task.DocumentType === "PowerPoint" &&
              this.props.imagesPreview.WordProjectDocumentPdfUpdatedBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
            {!this.state.hideView &&
              !this.state.showChanges &&
              this.props.task.DocumentType === "Word" &&
              this.props.WordProjectDocumentPdfUpdatedBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}

            {!this.state.hideView &&
              this.state.showChanges &&
              this.props.task.DocumentType === "PowerPoint" &&
              this.props.imagesPreview.WordProjectDocumentPdfOriginalBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}

            {!this.state.hideView &&
              this.state.showChanges &&
              this.props.task.DocumentType === "Word" &&
              this.props.WordDocumentBlacklinePdfUpdatedBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
          </span>
        );
      }
      if (this.state.previewMode == "projectOriginal") {
        previewMode = (
          <>
            <CustomRow name="Original Document">
              {this.cancel()}
              {this.props.task.DocumentType === "PowerPoint" ? (
                <LoadingOverlay
                  area="preview-mode"
                  inline="loading-overlay-inline-block"
                  hideContent={this.hideDocumentContent}
                />
              ) : (
                <LoadingOverlay
                  area="preview-mode"
                  inline="loading-overlay-inline-block"
                  loading={!this.props.WordProjectDocumentPdfOriginalBase64}
                  minDuration={100}
                />
              )}
            </CustomRow>
            {!this.state.hideView &&
              this.props.task.DocumentType === "PowerPoint" &&
              this.props.imagesPreview.WordProjectDocumentPdfOriginalBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
            {!this.state.hideView &&
              this.props.task.DocumentType === "Word" &&
              this.props.WordProjectDocumentPdfOriginalBase64?.map((m, index) => (
                <div className="row ml-2 mr-2" key={index}>
                  <img className="taskThumbnailWord" src={m} />
                </div>
              ))}
          </>
        );
      }
      if (this.context.officeAppType == "Powerpoint") {
        taskdocsView = (
          <div>
            {this.props.task.DocumentPowerpoint.map(m => (
              <div className="row ml-2 mr-1" key={m.Id}>
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">DOCUMENT</div>
                  <div className="float-right text-right mr-2 taskDetailsText">
                    <a href="#" title={m.Name} onClick={() => this.getTaskDocumentPowerpoint(m.FileNameId)}>
                      {m.Name}
                    </a>
                  </div>
                </div>
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">ORIGINAL</div>
                  <div className="float-right text-right mr-2 taskDetailsText"></div>
                </div>
                <div className="col-12 taskDetailsRow appFont">
                  {m.ThumbnailsOriginal.map(original => (
                    <span key={original.Id} className="mr-1">
                      <img className="taskThumbnail" src={original.Base64} />
                    </span>
                  ))}
                </div>
                <div className="col-12 taskDetailsRow appFont">
                  <div className="float-left taskDetailsLabel">UPDATED</div>
                  <div className="float-right text-right mr-2 taskDetailsText"></div>
                </div>
                <div className="col-12 taskDetailsRow appFont">
                  {m.ThumbnailsCurrent.map(updated => (
                    <span key={updated.Id} className="mr-1">
                      <img className="taskThumbnail" src={updated.Base64} />
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      }
      if (this.context.officeAppType == "Word") {
        taskdocsView = (
          <div>
            <div className="row ml-2 mr-1">
              <div className="col-12 taskDetailsRow appFont d-none">
                <div className="float-left taskDetailsLabel">DOCUMENT</div>
                <div className="float-right text-right mr-2 taskDetailsText">
                  <a
                    href="#"
                    title={this.props.task.WordDocumentFile}
                    onClick={() => this.getTaskDocumentWord(this.props.task.WordDocumentFileId)}
                  >
                    {this.props.task.WordDocumentFile}
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    if (this.props.task != null) {
      activeView = (
        <div>
          {!this.state.hide && (
            <div>
              <CustomRow name="Project Name" overlay={this.props.project?.Name}>
                {this.props.project?.Name}
              </CustomRow>
              <CustomRow name="Start Date">
                <Moment format="MM/DD/YYYY">
                  {this.props.task.TaskTypeId == 0
                    ? convertUTCDateToLocalDate(this.props.task.Created)
                    : this.props.task.Start}
                </Moment>
              </CustomRow>
              <CustomRow name="Due Date">
                <Moment format="MM/DD/YYYY">{this.props.task.Due}</Moment>
              </CustomRow>
              {this.props.task.TaskTypeId == 1 && (
                <CustomRow name="Task Type" overlay={this.props.task?.WorkItemTypeName}>
                  {this.props.task?.WorkItemTypeName}
                </CustomRow>
              )}
              <CustomRow name="Status">
                <TaskStatus
                  status={this.props.task?.StatusAndButtons?.Status}
                  lastActivity={this.props.task?.LastActivity?.Action}
                  customStatus={this.props.task?.Workflow?.Status}
                  statusEmail={this.props.task.StatusEmail}
                  statusEmailDateTime={this.props.task.StatusEmailDateTime}
                ></TaskStatus>
              </CustomRow>
              <CustomRow name="Last Activity">
                <LastActivity
                  lastActivity={this.props.task?.LastActivity?.Action}
                  lastActivityDateTime={this.props.task?.LastActivity?.CreatedString}
                ></LastActivity>
              </CustomRow>
              <CustomRow name="Priority">
                <TaskPriority priority={this.props.task.Priority}></TaskPriority>
              </CustomRow>
              {this.props.task.TaskTypeId == 1 && (
                <CustomRow name="Permission" overlay={this.props.task?.Permission}>
                  {(this.state.currUserId === this.props.task?.CreatedById || this.state.ownerGroup?.GroupId > 0) &&
                  this.props.task?.Permission != "Owner"
                    ? "Owner (" + this.props.task?.Permission + ")"
                    : this.props.task?.Permission}
                </CustomRow>
              )}
              <CustomRow name="Members">
                <div className="mr-2">
                  <TaskMembersPopover
                    projMembers={this.props.task.Members}
                    ownerId={this.props.task.CreatedById}
                    creator={this.props.task?.CreatedBy}
                    groups={this.props.task?.Groups}
                  ></TaskMembersPopover>
                </div>
              </CustomRow>
              {!this.context.DisableActions && (
                <CustomRow id="task-Actions" name="Task Actions">
                  {(this.context.officeAppType !== "Outlook" ||
                    (this.context.officeAppType === "Outlook" && this.props.task.TaskTypeId == 1)) && (
                    <span className="dropdown dropleft show">
                      <a
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-flip="false"
                      >
                        <img src={actionsIcon} className="task-icons" alt="Task Actions" />
                      </a>
                      <div className="dropdown-menu task-actions" aria-labelledby="dropdownMenuLink">
                        {taskActions}
                      </div>
                    </span>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  {this.props.task.TaskTypeId == 0 && (
                    <span className="dropdown dropleft show">
                      <a
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={editIcon} className="task-icons" alt="Edit Task" />
                      </a>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        {this.props.workflow?.DocumentButtons.includes("Live Document") && (
                          <a
                            className="dropdown-item appFont taskOptions"
                            href="#"
                            onClick={() => this.setPreviewMode("liveDocument")}
                          >
                            <img src={liveDocumentIcon} className="task-icons updated-icon" alt="Live Document" />
                            &nbsp;&nbsp;&nbsp;Live Document
                          </a>
                        )}
                        {this.props.workflow?.DocumentButtons.includes("Updated Document") && (
                          <a
                            className="dropdown-item appFont taskOptions"
                            href="#"
                            onClick={() => this.setPreviewMode("projectUpdated")}
                          >
                            <img src={updatedDocumentIcon} className="task-icons updated-icon" alt="Updated Document" />
                            &nbsp;&nbsp;&nbsp;Updated Document
                          </a>
                        )}
                        {this.props.workflow?.DocumentButtons.includes("Assigned Content") && (
                          <a
                            className="dropdown-item taskOptions"
                            href="#"
                            onClick={() => this.setPreviewMode("original")}
                          >
                            <img
                              src={assignedContentIcon}
                              className="task-icons assigned-icon"
                              alt="Assigned Content"
                            />
                            &nbsp;&nbsp;&nbsp;Assigned Content
                          </a>
                        )}
                        <a
                          className="dropdown-item taskOptions"
                          href="#"
                          onClick={() => this.setPreviewMode("projectOriginal")}
                        >
                          <img src={originalDocumentIcon} className="task-icons" alt="Original Document" />
                          &nbsp;&nbsp;&nbsp;Original Document
                        </a>
                        {this.props.workflow?.DocumentButtons.includes("Changed Content") && (
                          <a
                            className="dropdown-item taskOptions"
                            href="#"
                            onClick={() => this.setPreviewMode("updated")}
                          >
                            <img src={changedContentIcon} className="task-icons" alt="Changed Content" />
                            &nbsp;&nbsp;&nbsp;Changed Content
                          </a>
                        )}
                      </div>
                    </span>
                  )}
                </CustomRow>
              )}
              {/* Task Reminders */}
              {this.state.taskReminders?.length > 0 && (
                <CustomRow name="Scheduled Task Reminders">
                  <TaskRemindersHeader
                    expandReminders={this.state.expandReminders}
                    setExpandReminders={() => this.setState({ expandReminders: !this.state.expandReminders })}
                    headers={this.state.taskReminders}
                  />
                </CustomRow>
              )}
              <TaskReminders reminders={this.state.taskReminders} visible={this.state.expandReminders} />
              {/* Dynamic Fields */}
              <div>
                <TasksDynamicFieldsContent
                  projectTypeId={0}
                  task={this.props.task}
                  taskTypeId={this.props.task.workItemType}
                  objectId={this.props.task.Id}
                  objectType={"TaskDetail"}
                  sidePanelStyles={true}
                />
              </div>
              {previewMode}
              <div className="spacer"></div>
            </div>
          )}
          {this.state.showConfirm && (
            <div className="confirmation-in-task w-100">
              <div className="link-task-text">
                <img src={usingComputer} className="center using-computer-image" alt="Link Email"/>
                <div className="heading">Link Email to Task</div>
                <div className="sub-heading">{`Do you want to link the email(s) to the Task “${this?.context?.currentTask?.Name}”?`}</div>
              </div>
              <div className="fixed-bottom pt-4 pb-4 whiteBackground">
                <div className="row">
                  <div className="col-12 taskModeButtonContainer">
                    <button
                      className="loginButton btn-primary blueButtonOutline shadow-none taskModeButton"
                      onClick={() => {
                        this.setState(prevState => {
                          return {
                            ...prevState,
                            showConfirm: !prevState.showConfirm
                          };
                        });
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="loginButton btn-primary blueButton shadow-none float-right taskModeButton taskAddSubmitBtn"
                      onClick={() => {
                        this.setState(prevState => {
                          return {
                            ...prevState,
                            showConfirm: !prevState.showConfirm
                          };
                        });

                        addAuditTrail(this.context)
                          .then(data => {
                            if (data) {
                              ApiService.linkEmailTask(data, this.context).then(async result => {
                                if (result.status === 200 && result.data != null) {
                                  this.setState(prevState => {
                                    return {
                                      ...prevState,
                                      linkSuccess: true
                                    };
                                  });
                                } else {
                                  this.setState(prevState => {
                                    return {
                                      ...prevState,
                                      linkSuccess: false
                                    };
                                  });
                                }
                              });
                            }
                          })
                          .catch(error => {
                            console.error("Error:", error);
                            this.setState(prevState => {
                              return {
                                ...prevState,
                                linkSuccess: false
                              };
                            });
                          });
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.linkSuccess !== null ? (
            <InlineNotification
              type={this.state.linkSuccess === true ? "success" : "error"}
              title={this.state.linkSuccess === true ? "Success" : "Error"}
              description={
                this.state.linkSuccess === true ? `Email Linked Successfully.` : "Error while linking email."
              }
              style={{
                position: "fixed",
                bottom: "10px",
                left: "10px",
                width: "280px",
                backgroundColor: "#EBF5ED",
                border: "1px solid #1D7D2C"
              }}
              cancelClick={() =>
                this.setState(prevState => {
                  return {
                    ...prevState,
                    linkSuccess: null
                  };
                })
              }
            />
          ) : null}
        </div>
      );
    }
    return <div>{activeView}</div>;
  };
}

TaskDetailsHeader.propTypes = {
  task: PropTypes.object,
  project: PropTypes.object,
  workflow: PropTypes.object,
};

export default TaskDetailsHeader;
