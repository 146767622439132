import axios from "axios";

class ApiService {
  config(context) {
    const apiURL = context.apiEndpoint;
    const userToken = context.userToken;
    return {
      baseURL: apiURL,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    };
  }

  configSSO(context) {
    const apiURL = context.apiEndpoint;
    const userToken = context.userToken;
    return {
      baseURL: apiURL,
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`
      }
    };
  }

  configJWT(jwtUserToken) {
    return {
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtUserToken}`
      }
    };
  }

  /* Authentication */
  login(data, context) {
    return axios.post("/users/Login", data, this.config(context));
  }

  loginSSO(data, context) {
    return axios.get("/users/LoginSSO?timezone=" + data.timezone, this.configSSO(context));
  }

  logout(data, context) {
    const apiURL = context.apiEndpoint + "/users/Logout";
    return axios.post(apiURL, data, this.config(context));
  }

  getCurrentUser(context) {
    return axios.get("/users/GetCurrentUser", this.config(context));
  }

  getAllDepUsers(context) {
    return axios.get("/users/GetAllUsers", this.config(context));
  }

  checkTaskDocument(formData, context) {
    return axios.post("/tasks/CheckTaskDocument", formData, this.config(context));
  }

  /* Projects */
  getProjectDocument(id, context) {
    return axios.get("/projects/GetProjectDocument/" + id, this.config(context));
  }

  getProjectModel(context) {
    return axios.get("/projects/GetProjectModel", this.config(context));
  }

  createUnassignedProject(context) {
    return axios.get("/projects/CreateUnassignedProject", this.config(context));
  }

  addProject(data, context) {
    return axios.post("/projects/AddProject", data, this.config(context));
  }

  updateProjectEndDate(data, context) {
    return axios.patch("/projects/UpdateProjectEndDate", data, this.config(context));
  }

  addProjectFile(data, context) {
    return axios.post("/projects/AddProjectFile", data, this.config(context));
  }

  getProjectFileModel(context) {
    return axios.get("/projects/GetProjectFileModel", this.config(context));
  }

  getProjectTemplates(context) {
    return axios.get("/projects/GetProjectTemplates", this.config(context));
  }

  getDefaultProjectTemplate(context) {
    return axios.get("/project-template/GetDefaultTemplate", this.config(context));
  }

  getUserProjects(context) {
    return axios.get("/projects/getuserprojects", this.config(context));
  }

  getRolePrivileges(id, context) {
    return axios.get("/role-privileges/" + id, this.config(context));
  }

  getTaskModel(id, context) {
    return axios.get("/tasks/GetTaskModel/" + id, this.config(context));
  }

  getProject(id, context) {
    return axios.get("/projects/GetProject/" + id, this.config(context));
  }

  getProjectWithDep(id, context) {
    return axios.get("/projects/GetProjectWithDep/" + id, this.config(context));
  }

  addMemberToProject(projectId, memberId, context) {
    return axios.get(
      "/projects/AddMemberToProject?projectId=" + projectId + "&userId=" + memberId,
      this.config(context)
    );
  }

  addMembersToProject(projectId, memberIds, context) {
    return axios.get(
      "/projects/AddMembersToProject?projectId=" + projectId + "&userIds=" + memberIds,
      this.config(context)
    );
  }

  /* Tasks */
  addTaskPowerpoint(data, context) {
    return axios.post("/tasks/AddTaskPowerpoint", data, this.config(context));
  }

  addTaskWord(data, context) {
    return axios.post("/tasks/AddTaskWord", data, this.config(context));
  }

  addStandardTask(data, context) {
    return axios.post("/tasks/AddStandardTask", data, this.config(context));
  }

  linkEmailTask(data, context) {
    return axios.post("/tasks/LinkTaskToEmail", data, this.config(context));
  }

  deleteFileFromComment(data, context) {
    return axios.post(`/tasks/DeleteFileFromComment?fileId=${encodeURIComponent(data)}`, data, this.config(context));
  }

  getAnalyzedData(data, context) {
    return axios.post(`/TaskAnalysis/AnalyzeDataAsync`, data, this.config(context));
  }

  getToken(id, context) {
    return axios.get(`/TaskAnalysis/CountTokens?taskId=${id}`, this.config(context));
  }

  editStandardTask(data, context) {
    return axios.put(`/tasks/EditStandardTask`, data, this.config(context));
  }

  addTaskPowerpointDocuments(data, context) {
    return axios.post("/tasks/AddTaskPowerpointDocuments", data, this.config(context));
  }

  getSlidesSelected(data, context) {
    return axios.post("/tasks/GetSlidesSelected", data, this.config(context));
  }

  getTaskDocumentPowerpoint(id, context, statusId = 0) {
    return axios.get(`/tasks/GetTaskDocumentPowerpoint?id=${id}&statusId=${statusId}`, this.config(context));
  }

  getTaskDocumentWord(id, context) {
    return axios.get("/tasks/GetTaskDocumentWord/" + id, this.config(context));
  }

  getTaskPowerpointComplete(id, context) {
    return axios.get("/tasks/GetTaskPowerpointComplete/" + id, this.config(context));
  }

  getTaskWordComplete(id, context) {
    return axios.get("/tasks/GetTaskWordComplete/" + id, this.config(context));
  }

  /* getWordPreviewImages endpoint is deprecated endpoint that calls all together:
   -> getWordDocPdfOriginalBase64
   -> getWordDocPdfUpdatedBase64
   -> getWordProjectDocumentPdfPreviewBase64
   -> getWordProjectDocPdfOriginalBase64
   -> getWordProjectDocPdfUpdatedBase64
   -> getWordDocBlacklinePdfUpdatedBase64
  */
  getWordPreviewImages(id, context) {
    return axios.get("/tasks/GetWordPreviewImages/" + id, this.config(context));
  }
  // It's better to call getWordDocPdfOriginalBase64 separately to avoid waiting for all results at once...
  getWordDocPdfOriginalBase64(id, context) {
    return axios.get("/tasks/GetWordDocPdfOriginalBase64/" + id, this.config(context));
  }
  // It's better to call getWordDocPdfUpdatedBase64 separately to avoid waiting for all results at once...
  getWordDocPdfUpdatedBase64(id, context) {
    return axios.get("/tasks/GetWordDocPdfUpdatedBase64/" + id, this.config(context));
  }
  // It's better to call getWordProjectDocumentPdfPreviewBase64 separately to avoid waiting for all results at once...
  getWordProjectDocumentPdfPreviewBase64(id, context) {
    return axios.get("/tasks/getWordProjectDocPdfPreviewBase64/" + id, this.config(context));
  }
  // It's better to call getWordProjectDocPdfOriginalBase64 separately to avoid waiting for all results at once...
  getWordProjectDocPdfOriginalBase64(id, context) {
    return axios.get("/tasks/GetWordProjectDocPdfOriginalBase64/" + id, this.config(context));
  }
  // It's better to call getWordProjectDocPdfUpdatedBase64 separately to avoid waiting for all results at once...
  getWordProjectDocPdfUpdatedBase64(id, context) {
    return axios.get("/tasks/GetWordProjectDocPdfUpdatedBase64/" + id, this.config(context));
  }
  // It's better to call getWordDocBlacklinePdfUpdatedBase64 separately to avoid waiting for all results at once...
  getWordDocBlacklinePdfUpdatedBase64(id, context) {
    return axios.get("/tasks/GetWordDocBlacklinePdfUpdatedBase64/" + id, this.config(context));
  }

  getUserTasks(context) {
    return axios.get("/tasks/GetUserTaskListForAddin", this.config(context));
  }

  getUserTasksList(context) {
    return axios.get("/tasks/GetUserTasksList", this.config(context));
  }

  getUserTasksRegular(context) {
    return axios.get("/tasks/GetUserTasksRegular", this.config(context));
  }

  getTask(id, context) {
    return axios.get("/tasks/GetTask/" + id, this.config(context));
  }

  getEmailTask(emailConversationId, context) {
    return axios.get(
      "/tasks/GetEmailTask?emailConversationId=" + encodeURIComponent(emailConversationId),
      this.config(context)
    );
  }

  getEmailTaskBySubject(subject, context) {
    return axios.get("/tasks/GetEmailTaskBySubject?subject=" + encodeURIComponent(subject), this.config(context));
  }

  getEmailTaskBySubjectCompose(subject, context) {
    return axios.get(
      "/tasks/GetEmailTaskBySubjectCompose?subject=" + encodeURIComponent(subject),
      this.config(context)
    );
  }

  getEmailTaskBySubjectAndDate(data, context) {
    return axios.post("/tasks/GetEmailTaskBySubjectAndDate", data, this.config(context));
  }

  addEmailAuditTrail(data, context) {
    console.log(data);
    return axios.post("/email/AddEmailAuditTrail", data, this.config(context));
  }

  addEmailAuditTrailUndeliverable(data, context) {
    console.log(data);
    return axios.post("/email/addEmailAuditTrailUndeliverable", data, this.config(context));
  }

  updateEmailTaskComposeMode(data, context) {
    console.log("UpdateEmailTaskComposeMode =>");
    console.log(data);
    return axios.post("/tasks/UpdateEmailTaskComposeMode", data, this.config(context));
  }

  getRegularTask(id, context) {
    return axios.get("/tasks/GetRegularTask/" + id, this.config(context));
  }

  addTaskComment(data, context) {
    console.log("Comment Context / Data -> ");
    console.log(context);
    console.log(data);
    return axios.post("/tasks/AddTaskCommentLegacy", data, this.config(context));
  }

  addTaskDocumentComment(data, context) {
    return axios.post("/tasks/AddTaskDocumentCommentLegacy", data, this.config(context));
  }

  addTaskRegularComment(data, context) {
    return axios.post("/tasks/AddTaskRegularCommentLegacy", data, this.config(context));
  }

  getDocumentTaskComments(taskID, context) {
    return axios.get("/tasks/GetTaskDocumentCommentsLegacy/" + taskID, this.config(context));
  }

  getRegularTaskComments(taskID, context) {
    return axios.get("/tasks/GetTaskRegularCommentsLegacy/" + taskID, this.config(context));
  }

  getTaskAuditTrailDocumentOutlook(taskID, context) {
    return axios.get("/tasks/GetTaskAuditTrailDocumentOutlook/" + taskID, {
      ...this.config(context),
      responseType: "arraybuffer"
    });
  }

  updateTaskPowerpointDocument(data, context) {
    return axios.post("/tasks/UpdateTaskPowerpointDocument", data, this.config(context));
  }

  updateTaskWordDocument(data, context) {
    return axios.post("/tasks/UpdateTaskWordDocument", data, this.config(context));
  }

  submitTaskWordDocument(data, context) {
    return axios.post("/tasks/SubmitTaskWordDocument", data, this.config(context));
  }

  submitTaskPowerPointDocument(data, context) {
    return axios.post("/tasks/SubmitTaskPowerPointDocument", data, this.config(context));
  }

  declineTaskWordDocument(data, context) {
    return axios.post("/tasks/DeclineTaskWordDocument", data, this.config(context));
  }

  declineTaskPowerPointDocument(data, context) {
    return axios.post("/tasks/DeclineTaskPowerPointDocument", data, this.config(context));
  }

  getFileDisplayProjects(data, context) {
    return axios.post("/projects/GetFileDisplayProjects", data, this.config(context));
  }

  getFileName(data, context) {
    return axios.post("/projects/GetFileNameFromAddin", data, this.config(context));
  }

  getAllTaskMembers(id, context) {
    return axios.get("/tasks/MembersList/" + id, this.config(context));
  }

  reassignTaskMembers(data, context) {
    return axios.post("/tasks/ReassignMembers", data, this.config(context));
  }

  cancelTask(data, context) {
    return axios.post("/tasks/CancelTask", data, this.config(context));
  }

  downloadAllAttachmentsRegularZip(id, context) {
    return axios.get("/tasks/DownloadAllAttachmentsRegularZip?commentId=" + id, {
      baseURL: context.apiEndpoint,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${context.userToken}`,
        Accept: "application/zip"
      }
    });
  }

  downloadAllAttachmentsDocumentZip(id, context) {
    return axios.get("/tasks/DownloadAllAttachmentsDocumentZip?commentId=" + id, {
      baseURL: context.apiEndpoint,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${context.userToken}`,
        Accept: "application/zip"
      }
    });
  }

  // If you make improvements here - double check download_file_Mac.html
  downloadCommentAttachment(id, mimeType, data, context) {
    return axios.post("/tasks/DownloadCommentAttachment/" + id, data, {
      baseURL: context.apiEndpoint,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${context.userToken}`,
        Accept: mimeType
      }
    });
  }

  // If you make improvements here - double check download_file_Mac.html
  downloadRegularCommentAttachment(id, mimeType, data, context) {
    return axios.post("/tasks/DownloadCommentRegularAttachment/" + id, data, {
      baseURL: context.apiEndpoint,
      responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${context.userToken}`,
        Accept: mimeType
      }
    });
  }

  downloadOriginalWordDocument(id, formData, context) {
    return axios.post("/Tasks/DownloadWordVersionDocument/" + id, formData, this.config(context));
  }

  downloadOriginalPPTDocument(id, formData, context) {
    return axios.post("/Tasks/DownloadPPTVersionDocument/" + id, formData, this.config(context));
  }

  checkProjectName(data, context) {
    return axios.post("/Projects/CheckProjectName?projectName=" + encodeURIComponent(data), data, this.config(context));
  }

  getTaskWithWorkflow(TaskId, taskType, context) {
    return axios.get("/tasks/GetTaskWorflowButtons?TaskId=" + TaskId + "&taskType=" + taskType, this.config(context));
  }

  getTaskReminders(id, context) {
    return axios.get("/tasks/GetTaskReminders/" + id, this.config(context));
  }

  getTaskTypes(context) {
    return axios.get("/task-types", this.config(context));
  }

  getDefaultTaskType(context) {
    return axios.get("/task-types/default-task-type", this.config(context));
  }

  getAllNotificationByUser(context) {
    return axios.get("/Notifications/GetAllNotificationByUser", this.config(context));
  }

  clickNotification(id, context) {
    return axios.post("/Notifications/ClickNotification/" + id, id, this.config(context));
  }

  /* ACTIONS Task */
  ApproveTaskRegular(data, context) {
    return axios.post("/tasks/ApproveTaskRegular", data, this.config(context));
  }

  DeclineTaskRegular(data, context) {
    return axios.post("/tasks/DeclineTaskRegular", data, this.config(context));
  }

  CancelTaskRegular(data, context) {
    return axios.post("/tasks/CancelTaskRegular", data, this.config(context));
  }

  RequestReviewTaskRegular(data, context) {
    return axios.post("/tasks/RequestReviewTaskRegular", data, this.config(context));
  }

  ReassignTask(data, context) {
    return axios.post("/tasks/ReassignTask", data, this.config(context));
  }

  ReminderTaskRegular(data, context) {
    return axios.post("/tasks/ReminderTaskRegular", data, this.config(context));
  }

  ChangeTaskStatus(data, context) {
    return axios.post("/tasks/ChangeTaskStatus", data, this.config(context));
  }
  /* Dynamic Fields */
  getDynamicFields(dynamicFieldsRequestDto, context) {
    const url = this.createURLWithQueryParams(context.apiEndpoint + "/dynamic-fields", dynamicFieldsRequestDto);
    return axios.get(url, this.config(context));
  }

  saveDynamicFields(dynamicFieldsDataDto, context) {
    return axios.post("dynamic-fields/", dynamicFieldsDataDto, this.config(context));
  }

  createURLWithQueryParams(url, queryParams = {}) {
    const newUrl = new URL(url);

    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] == null) return;
      newUrl.searchParams.append(key, queryParams[key]);
    });
    return newUrl;
  }

  /* Email Tracking */
  checkUserEmailExists = (email, context) => {
    return axios.get("/auth/CheckUserEmailExists?email=" + email, this.config(context));
  };

  checkUserEmailsExists = (emails, context) => {
    return axios.post("/auth/CheckUserEmailsExists", emails, this.config(context));
  };

  addEmailPixel(data, context) {
    return axios.post("/emailpixel", data, this.config(context));
  }

  getEmailParser(context) {
    return axios.get("/emailparser/", this.config(context));
  }

  getEmailParserType(context) {
    return axios.get("/emailparsertype/", this.config(context));
  }

  getEmailPixel(id, context) {
    return axios.get("/email/" + id, this.config(context));
  }

  getDefaultEmailTaskType(context) {
    return axios.get("/tasks/GetDefaultEmailTaskType", this.config(context));
  }
  getGroupsFromEmail(email, context) {
    return axios.get("/groups/GetGroupsFromEmail?email=" + email, this.config(context));
  }

  getSettings(context) {
    return axios.get("/settings/", this.config(context));
  }  
}

export default new ApiService();
