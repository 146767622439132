export const taskNameToolTipInfo =
  "This field will sync with the email subject line when auto-sync is enabled. To edit manually, disable auto-sync.";
export const assignedMembersToolTipInfo =
  "This field will sync with the email recipients when auto-sync is enabled. To edit manually, disable auto-sync.";
export const otherMembersToolTipInfo = "These members are not part of the selected project.";

export const TaskStatusEnums = {
  Assigned: 1,
  Completed: 2,
  Approved: 3,
  Declined: 4,
  Reassigned: 5,
  Published: 6,
  Comment: 7,
  Cancelled: 8,
  InProgress: 9,
  PendingReview: 10,
  PendingApproval: 11,
};

export const CommentActions = Object.freeze({
  Assigned: 1,
  Completed: 2,
  Approved: 3,
  Declined: 4,
  Reassigned: 5,
  Published: 6,
  Comment: 7,
  Cancelled: 8,
  Rejected: 9,
  Updated: 10,
  Submitted: 11,
  Reminded: 12,
  Submitted_Form: 13,
  Status_Changed: 15,
  Auto_Reminder: 16,
  Reopened: 17,
  Form_Submitted: 18,
  Form_Saved: 19,
  AI_Interaction:20,
  Email_Sent: 101,
  Email_Read: 102,
  Email_Response_Internal: 103,
  Email_Bounced: 104,
  Email_Response_External: 105,
  Email_Forwarded_Internal: 106,
  Email_Forwarded_External: 107,
  Out_of_Office: 110
});

export const AI_TASK_CREATION = "AI_User";
export const AI_DEFAULT_TASK_CREATION = "AI_Task_Types";
