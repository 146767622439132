import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import PropTypes from "prop-types";

const TaskAuditAiInteraction = ({
  userInitials,
  startDate,
  userEmail,
  commentClass,
  userComments
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const formatComment = () => {
    let splitComment = userComments.split("<rbr/>");
    let userQuestion = splitComment[0];
    let aiResponse = splitComment[1];

    const displayedResponse = isExpanded || aiResponse.length <= 75 ? aiResponse : aiResponse.substring(0, 75) + " ...";
    const theObj = { __html: displayedResponse };

    return (
      <>
        <div>
          <span className="font-weight-bold">{`${userInitials}: `}</span>
          <span style={{ whiteSpace: "normal" }}>{`${userQuestion}`}</span>
        </div>
        <div>
          <span className="font-weight-bold">AI Response: </span>
          <span style={{ whiteSpace: "normal" }} dangerouslySetInnerHTML={theObj}></span>
        </div>
      </>
    );
  };

  return (
    <div className="mt-2 w-100">
      <div className="d-flex commentLoadAnimation">
        <OverlayTrigger placement="top" overlay={<Tooltip>{userEmail}</Tooltip>}>
          <div className="userInitialsAudit pt-2">{userInitials}</div>
        </OverlayTrigger>

        <div className="taskAuditDetails flex-grow-1">
          <div className="d-flex align-items-start">
            <div className="taskAuditDateTimeText align-items-start">
              <Moment format="MM/DD/YYYY">{startDate}</Moment> <Moment format="h:mm a">{startDate}</Moment>
              &nbsp;
              <span className="taskAuditDownloadEmail">
                via&nbsp;
                <a className="taskAuditDownloadViaEmail mt-2" onClick={() => setIsExpanded(!isExpanded)}>
                  TRIYO AI
                </a>
                {/* <img src={DownloadButton} alt="download-file" /> */}
              </span>
            </div>
            <div className="taskAuditStatus align-items-end flex-shrink-1">
              <OverlayTrigger placement="top" overlay={<Tooltip>AI Interaction</Tooltip>}>
                <div className="taskAuditStatusContainer">
                  <div className="taskAuditStatusSpan">AI Interaction</div>
                </div>
              </OverlayTrigger>
            </div>
          </div>
          <div className="taskAuditComment">
            <OverlayTrigger placement="top" overlay={<Tooltip>{userEmail}</Tooltip>}>
              <span>TRIYO AI</span>
            </OverlayTrigger>
          </div>
          <div className="taskAuditComment taskAuditSubName">System</div>
          <span className={commentClass}>
            <span className="task-audit-comment-container">
              <span>{formatComment()}&nbsp;</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

TaskAuditAiInteraction.propTypes = {
  userInitials: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  commentClass: PropTypes.string.isRequired,
  downloadEmailDocument: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  userComments: PropTypes.string.isRequired
};

export default TaskAuditAiInteraction;
