//import { parseEmailHeaders } from "../../helpers/mailParserUtils";

// let fetchHeaders = new Promise((resolve, reject) => {
//   try {
//     if (Office.context.mailbox.item) {
//       Office.context.mailbox.item.getAllInternetHeadersAsync(getHeadersAsyncResult2 => {
//         if (getHeadersAsyncResult2.status === Office.AsyncResultStatus.Succeeded) {
//           console.log("[getAllInternetHeadersAsync] succeeded.");
//           if (getHeadersAsyncResult2.value) {
//             resolve(getHeadersAsyncResult2.value);
//           } else {
//             console.log("Email doesn't contain headers");
//             resolve(null);
//           }
//         } else {
//           //This usually happens when the email is in sent folder. We can't access the headers in sent folder with Office Js.
//           console.log("No Headers found or getAllInternetHeadersAsync function is not available.");
//           resolve(null);
//         }
//       });
//     } else {
//       //This usually happens when the email is in sent folder. We can't access the headers in sent folder with Office Js.
//       console.log("No Headers found or getAllInternetHeadersAsync function is not available.");
//       resolve(null);
//     }
//   } catch (error) {
//     console.log("Error in [fetchHeaders]=> ", error);
//     reject(error);
//   }
// });

// let fetchCustomProperties = new Promise((resolve, reject) => {
//   try {
//     if (Office.context.mailbox.item) {
//       Office.context.mailbox.item.loadCustomPropertiesAsync(customPropertiesAsyncResult1 => {
//         let _customProps = customPropertiesAsyncResult1.value;
//         let dictionary = _customProps.getAll();
//         console.log("[loadCustomPropertiesAsync] succeeded.");
//         console.log(dictionary);
//         resolve(dictionary);
//       });
//     } else {
//       console.log("No Custom Properties found or loadCustomPropertiesAsync function is not available.");
//       resolve(null);
//     }
//   } catch (error) {
//     console.log("Error in [fetchCustomProperties]=> ", error);
//     reject(error);
//   }
// });

// let emailItem = {
//   item: context.currEmail.item
// };
// context.prevEmail = emailItem;

function fetchBodyHtml() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync("html", asyncResult => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    });
  });
}

function fetchBodyText() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync("text", asyncResult => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    });
  });
}

let fetchSingleAttachment = function(attachment, index, count) {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.getAttachmentContentAsync(attachment.id, asyncResult => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        attachment.Base64String = asyncResult.value.content;
        attachment.index = index;
        resolve(attachment);
      } else {
        reject(asyncResult.error);
      }
    });
  });
};

function fetchAttachment() {
  return new Promise((resolve, reject) => {
    let att = [];
    let count = 0;
    Office.context.mailbox.item.attachments.forEach((attachment, index) => {
      let value = fetchSingleAttachment(attachment, index, count);
      value.then(result => {
        att.push(result);
        count++;
        if (count == Office.context.mailbox.item.attachments.length) resolve(att);
      });
    });
    if (Office.context.mailbox.item.attachments.length == 0) resolve(att);
  });
}

export function addAuditTrail(context) {
  // const htmlBody = await fetchBodyHtml;
  // console.log("htmlBody->");
  // //const htmlHeader = await fetchHeaders;
  // const textBody = await fetchBodyText;
  // console.log("textBody->");
  // const attachmentsResult = await fetchAttachment;
  // let attachmentMap = attachmentsResult.map(aR => {
  //   return {
  //     Id: aR.id,
  //     FileName: aR.name,
  //     Base64String: aR.Base64String,
  //     FileContentType: aR.contentType
  //   };
  // });
  // console.log("attachmentMap->");
  // let encodedHtmlBody = encodeURIComponent(JSON.stringify(htmlBody));
  // // let ccEmails = [];
  // // Office?.context?.mailbox?.item?.cc.forEach(cc => {
  // //   ccEmails.push(cc?.emailAddress);
  // // });
  // // console.log("ccEmails->");
  // let headersObject = {
  //   Sent: context.currEmail.item.dateTimeCreated,
  //   From: context.currEmail.item.from,
  //   To: context.currEmail.item.to,
  //   Cc: context.currEmail.item.cc,
  //   Bcc: context.currEmail.item.bcc,
  //   Subject: context.currEmail.item.subject
  // };

  // let data = {
  //   Id: context.currentTask.Id,
  //   Name: context.currentTask.Name,
  //   CreatedById: context.currentTask.CreatedById,
  //   Created: context.currentTask.Created,
  //   Description: textBody,
  //   HtmlEmailBody: encodedHtmlBody,
  //   taskCommentFiles: attachmentMap,
  //   EmailId: context.currEmail.item.itemId,
  //   EmailConversationId: context.currEmail.item.conversationId,
  //   EmailThread: context.currEmail.item.subject,
  //   EmailHeaders: encodeURI(JSON.stringify(headersObject)),
  //   EmailCreated: context.currEmail.item.dateTimeCreated,
  //   SenderEmail: context.currEmail.item.from.emailAddress

  //   //CCEmailAddress: ccEmails,
  //   //InternalOrg: false
  // };

  // // let parsedHeaders = parseEmailHeaders(htmlHeader);
  // // data.InternalOrg = parsedHeaders["x-ms-exchange-organization-authas"] == "Internal";
  // return data;
  return fetchBodyHtml()
    .then(htmlBody => {
      return fetchBodyText().then(textBody => {
        return fetchAttachment().then(attachmentsResult => {
          const attachmentMap = attachmentsResult.map(aR => ({
            Id: aR.id,
            FileName: aR.name,
            Base64String: aR.Base64String,
            FileContentType: aR.contentType
          }));

          let headersObject = {
            Sent: context.currEmail.item.dateTimeCreated,
            From: context.currEmail.item.from,
            To: context.currEmail.item.to,
            Cc: context.currEmail.item.cc,
            Bcc: context.currEmail.item.bcc,
            Subject: context.currEmail.item.subject
          };

          let data = {
            Id: context.currentTask.Id,
            Name: context.currentTask.Name,
            CreatedById: context.currentTask.CreatedById,
            Created: context.currentTask.Created,
            Description: textBody,
            HtmlEmailBody: encodeURIComponent(JSON.stringify(htmlBody)),
            taskCommentFiles: attachmentMap,
            EmailId: context.currEmail.item.itemId,
            EmailConversationId: context.currEmail.item.conversationId,
            EmailThread: context.currEmail.item.subject,
            EmailHeaders: encodeURI(JSON.stringify(headersObject)),
            EmailCreated: context.currEmail.item.dateTimeCreated,
            SenderEmail: context.currEmail.item.from.emailAddress
          };
          return data;
        });
      });
    })
    .catch(error => {
      console.error("Error fetching email data:", error);
      throw error; // Rethrow error if needed
    });
}
