import React, { useContext, useEffect, useRef, useState } from "react";
import ChatSuggestion from "./chat-suggestion";
import ChatHeader from "./chat-header";
import ChatMessageUser from "./chat-message-user";
import ChatMessageAssistant from "./chat-message-assistant";
import PropTypes from "prop-types";
import { scrollToBottomById } from "../../../util/general-helpers";
import FluentUiIcon from "../../../icons/fluent-ui-icon";
import Form from "react-bootstrap/Form";
import ApiService from "../../../services/ApiService";
import AppContext from "../../../app/AppContext";

const suggestions = [
  {
    text: "Summarize Audit Trail",
    prompt: `Can you provide a concise summary of the following text? 
    The summary should cover all the key points and main ideas presented in the original text, 
    while also condensing the information into a concise and easy-to-understand format. 
    Please ensure that the summary includes relevant details and examples that support the main ideas, 
    while avoiding any unnecessary information or repetition. The length of the summary should be appropriate for the length 
    and complexity of the original text, providing a clear and accurate overview without omitting any important information`,
    message: "Provide a concise summary of the audit trail."
  },
  {
    text: "Identify key actionable items",
    prompt: "Identify key actionable items and make it concise from the following content:",
    message: "Identify key actionable items"
  }
];

export default function Chat({ chatMessages, setChatMessages, setShowForm }) {
  const textBoxRef = useRef(null);
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    textBoxRef.current.focus();
  }, []);

  const FetchAssistantData = async (prompt, text) => {
    setLoading(true);

    let query = {
      TaskId: context.currentTask.Id,
      Query: prompt,
      Prompt: text
    };

    let result = await ApiService.getAnalyzedData(query, context);

    if (result.status === 200) {
      setLoading(false);
      return result.data;
    } else {
      setLoading(false);
      console.log("Audit Trail Logging Failed");
    }
  };

  const FetchUserData = async suggestion => {
    setChatMessages(prev => [
      ...prev,
      {
        role: "user",
        content: suggestion.message
      }
    ]);

    let data = await FetchAssistantData(suggestion.prompt, suggestion.text);
    setChatMessages(prev => [...prev, { role: "assistant", content: data?.Response }]);

    scrollToBottomById("chat-conversation-identifier");
  };

  const OnInput = async () => {
    if (value.trim() === "") return;

    let suggestion = {
      text: "",
      prompt: `${value}`,
      message: value
    };

    setChatMessages(prev => [...prev, { role: "user", content: suggestion.message }]);
    setValue("");
    scrollToBottomById("chat-conversation-identifier");

    let data = await FetchAssistantData(suggestion.prompt, null);

    setChatMessages(prev => [...prev, { role: "assistant", content: data?.Response }]);
    scrollToBottomById("chat-conversation-identifier");
  };

  const OnKeyDown = e => {
    if (e.key === "Enter") {
      OnInput();
    }
  };

  return (
    <div className="d-flex flex-column chat-container mb-4">
      <div className="d-flex align-align-items-center chat-header">
        <ChatHeader setShowForm={setShowForm}></ChatHeader>
      </div>
      <div id="chat-conversation-identifier" className="d-flex flex-column chat-conversation overflow-auto mt-2">
        {chatMessages.map(message =>
          message.role === "assistant" ? (
            <ChatMessageAssistant key={message.content} message={message.content} />
          ) : (
            <ChatMessageUser key={message.content} message={message.content} />
          )
        )}
      </div>
      <div className="d-flex chat-suggestions mt-2">
        {suggestions.map(suggestion => (
          <ChatSuggestion text={suggestion.text} onClick={() => FetchUserData(suggestion)} />
        ))}
      </div>
      <div className="chat-input d-flex align-items-center mt-2 justify-content-between">
        <div className="chat-input-text w-100">
          <Form.Control
            className="form-control form-custom-control shadow-none projectDescription"
            as="textarea"
            id="commentNote"
            name="commentNote"
            rows="3"
            value={value}
            ref={textBoxRef}
            onChange={e => setValue(e.target.value)}
            onKeyDown={e => OnKeyDown(e)}
            placeholder="Enter comment"
          />
        </div>
        <div
          className="d-flex justify-content-center align-items-center chat-input-button"
          onClick={loading ? null : OnInput}
        >
          {loading ? (
            <FluentUiIcon name={"SpinnerIosRegular"} color={"#fff"} size={18} />
          ) : (
            <FluentUiIcon name={"SendRegular"} color={"#fff"} size={18} />
          )}
        </div>
      </div>
    </div>
  );
}

Chat.propTypes = {
  setShowForm: PropTypes.func.isRequired,
  chatMessages: PropTypes.array.isRequired,
  setChatMessages: PropTypes.func.isRequired
};
