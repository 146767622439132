import React from "react";
import {
    ClipboardTextLtrRegular as FluentClipboardTextLtrRegular,
    TasksAppFilled as FluentTasksAppFilled,
    ArrowDownloadRegular as FluentArrowDownloadRegular,
    SpinnerIosRegular as FluentSpinnerIosRegular,
    SendRegular,
    ChevronLeftRegular,
    MailFilled,
  } from '@fluentui/react-icons';

  export const FluentIcons = {
    ClipboardTextLtrRegular: (props) => <FluentClipboardTextLtrRegular {...props} />,
    TasksAppFilled: (props) => <FluentTasksAppFilled {...props} />,
    ArrowDownloadRegular: (props) => <FluentArrowDownloadRegular {...props} />,
    SpinnerIosRegular: (props) => <FluentSpinnerIosRegular {...props} />,
    SendRegular: (props) => <SendRegular {...props} />,
    ChevronLeftRegular: (props) => <ChevronLeftRegular {...props} />,
    MailFilled: (props) => <MailFilled {...props} />
  };