import React, { useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { validFileTypes, isImageFile, ToBase64, validFileTypesString } from '../../util/file-helpers.js';
import { isDynamicFieldsValid, isAllDynamicFieldsEmpty } from "../dynamicFields/utils/utils.js";
import attachmentIcon from "../../resources/Icons/Icon - Attachments.svg";
import sendIcon from "../../resources/Icons/Icon - Send.svg";
import removeAttachment from "../../resources/Icons/Icon - Remove Attachment.svg";

const TaskAuditCommentForm = ({
  taskType,
  taskId,
  selectedFiles,
  setSelectedFiles,
  newCommentText,
  setNewCommentText,
  addTaskComment,
  setRenderComments,
  dynamicFields,
  activeTab,
  setDynamicFields,
  setDisableDynamicError
}) => {
  const [displayAlert, setDisplayAlert] = useState("");
  const [isError, setIsError] = useState(false);
  const [emptyCommentMessage, setEmptyCommentMessage] = useState(false);
  const [isAllFieldsEmpty, setIsAllFieldEmpty] = useState(false);

  const displayError = () => {
    setTimeout(() => {
      setIsError(false);
    }, 5000);
  };

  const showError = (message) => {
    setDisplayAlert(message);
    setIsError(true);
    displayError();
  }

  const checkInvalidFile = fileObj => {
    if (fileObj) {
      if (!validFileTypesString.includes(fileObj.name.toLowerCase().split(".").pop())) {
        showError(`File format must be ${validFileTypesString}`);
        return false;
      } else if (fileObj.size > 52428800) {
        showError("File size exceeds 50MB.");
        return false;
      } else if (fileObj.size > 10485760 && isImageFile(fileObj)) {
        showError("File size exceeds 10MB.");
        return false;
      }
      setIsError(false);
      return true;
    } else {
      showError("File cannot be uploaded.");
      return false;
    }
  };

  const onUploadingFiles = (action) => {
    let files = selectedFiles;
    Promise.all(
      files.map(file => {
        if (!checkInvalidFile) {
          return {};
        } else {
          return ToBase64(file).then(result => {
            let mimetype = result.substr(result.indexOf(":") + 1, result.indexOf(";") - result.indexOf(":") - 1);
            let thedocAsBytes = result.split(",")[1];
            return { FileName: file.name, FileContentType: mimetype, FileByteArray: thedocAsBytes, TaskId: taskId };
          });
        }
      })
    ).then(convertedFiles => {
      addTaskComment({
        TaskId: taskId,
        TaskType: taskType,
        Note: newCommentText,
        ActionId: action === "dynamic" ? 13 : 7,
        Action: action === "dynamic" ? "13" : "7",
        Files: convertedFiles
      }, dynamicFields,clearAfterSubmit);
    });

  };

  const addNewCommentFile = event => {
    event.preventDefault();
    event.stopPropagation();

    let files = selectedFiles;
    let newSelectedFiles = Array.from(event.target.files);
    event.target.value = null;

    if (files.length === 0) {
      newSelectedFiles.forEach(file => {
        if (!checkInvalidFile(file)) {
          return;
        }
        files.push(file);
      });
    } else {
      let fileFound = false;
      newSelectedFiles.forEach(file => {
        for (let i = 0; i < files.length; i++) {
          if (files[i].name === file.name) {
            fileFound = true;
          }
        }
        if (!fileFound) {
          if (!checkInvalidFile(file)) {
            return;
          }
          files.push(file);
        }
      });
    }

    setSelectedFiles(files);
    setRenderComments(true);
  };

  const removeNewCommentFile = (event, fileName) => {
    event.preventDefault();
    event.stopPropagation();
    let files = selectedFiles;
    let idx = files.findIndex(file => file.name === fileName);
    files.splice(idx, 1);
    setSelectedFiles(files);
    setRenderComments(true);
  };

  const isCommentsEmpty = () => {
    const afterTrime=newCommentText.trim();
    if (afterTrime == "") {
      newCommentText = afterTrime;
    }
    return (newCommentText === "" && selectedFiles.length === 0);
  }

  const isCommentsSubmitDisabled = !isDynamicFieldsValid(dynamicFields);

  const onSubmitCommentForm = (event, action) => {
    if (isCommentsSubmitDisabled && action === "dynamic") {
      setDisableDynamicError(false);
      return;
    }
    if (isCommentsEmpty() && activeTab =="Comments") {
      setEmptyCommentMessage(true);
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (action === "dynamic" &&  isAllDynamicFieldsEmpty(dynamicFields)) {
      setIsAllFieldEmpty(true);
      event.preventDefault();
      event.stopPropagation();
      return;
    } else {
      setIsAllFieldEmpty(false);
    }
    event.preventDefault();
    onUploadingFiles(action);
    setNewCommentText("");
    setSelectedFiles([]);
  };

  const clearAfterSubmit=()=>{
    let temp = [...dynamicFields];
    temp.forEach(d => {
      if (d.FieldType === "Checkbox")
        d.Data = "TriyoReset";
      else
        d.Data = "";
    });
    setDynamicFields(temp);
  }

  const resetForm = event => {
    event.preventDefault();
    let temp = [...dynamicFields];
    temp.forEach(d => {
      if (d.FieldType === "Checkbox")
        d.Data = "TriyoReset";
      else
        d.Data = "";
    });

    setNewCommentText("");
    setDynamicFields(temp);
  }

  const getCommentButtons = (icon, title, action, onChange = null) => (
    <div className="file-input-btn">
      <label htmlFor="file-input" className="w-10">
        <img src={icon} alt={title} />
        <input multiple type={action} style={{ cursor: "pointer" }} onChange={event => onChange(event)} />
      </label>
    </div>
  );

  return (
    <div>
      <form onSubmit={onSubmitCommentForm}>
        { activeTab == "Comments" &&
          <div className={(emptyCommentMessage && activeTab == "Comments")?"redBorder mt-2 commentContainer":"mt-2 commentContainer"}>
            <textarea
              placeholder="Enter Comment"
              className="form-control form-custom-control shadow-none task-comment-box-cancel"
              rows="3"
              value={newCommentText}
              onChange={e => {
                setEmptyCommentMessage(false);
                setNewCommentText(e.target.value);
              }}
            />
            <div className="row">
              <div className="col-12">
                <div className={"task-attachment" + (isError ? " invalid-border" : "")}>
                  <div className="d-flex justify-content-start align-items-flex-start image-upload" style={{ marginLeft: "auto" }}>
                    <div className="pr-1 pb-1">
                      {getCommentButtons(attachmentIcon, "attachFile", "file", addNewCommentFile)}
                    </div>
                    {isError ?
                    <div className="pb-1 errorDisplayMessage ">{displayAlert}</div>
                    :<div className="attachedFiles">
                      {selectedFiles.map(file => {
                        return (
                          <div className="file-pill pl-1 pr-1 pb-1" key={file.name}>
                            <div className="fileAttachment w-10">
                              <OverlayTrigger
                                placement="auto-start"
                                delay={{ show: 250, hide: 50 }}
                                overlay={
                                  <Tooltip id="tooltip-1" className="customTooltip" placement="auto-start">
                                    {file.name}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  { file.name.length > 5
                                    ? `${file.name.slice(0, 5)}...${file.name.slice(
                                      file.name.length - 4,
                                      file.name.length
                                    )}`
                                    : file.name
                                  }
                                </span>
                              </OverlayTrigger>
                              <div className="w-1 float-right">
                                <img
                                  src={removeAttachment}
                                  className="pointer float-right"
                                  alt="Remove Attachment"
                                  onClick={e => {
                                    removeNewCommentFile(e, file.name);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>}
                    {(activeTab === "Comments") && <div className="ml-auto pl-1 pb-1 d-flex">{getCommentButtons(sendIcon, "sendComment", "submit")}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        { emptyCommentMessage && activeTab == "Comments" &&
          <div className="emptyCommentErrMessage">
            Please enter a Comment
          </div>
        }
        { isAllFieldsEmpty && activeTab != "Comments" &&
          <div className="emptyCommentErrMessage">
            Please enter atleast one field
          </div>
        }
        { activeTab != "Comments" &&
          <div className={`col-12 taskModeButtonContainer pt-4 pb-3 dynamicSubmitContainer`}>
            <button className="blueButtonOutline mr-3" onClick={(e) => resetForm(e)}>
              Reset Form
            </button>
            <button className={"blueButton"} type="button" onClick={(e) => {onSubmitCommentForm(e, "dynamic")}}>
              Submit
            </button>
          </div>
        }
      </form>
    </div>
  );
};

TaskAuditCommentForm.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  selectedFiles: PropTypes.array,
  setSelectedFiles: PropTypes.func,
  newCommentText: PropTypes.string,
  setNewCommentText: PropTypes.func,
  addTaskComment: PropTypes.func,
  setRenderComments: PropTypes.func,
  dynamicFields: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  setDynamicFields: PropTypes.func,
  setDisableDynamicError: PropTypes.func
};

export default TaskAuditCommentForm;
