import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { Container, Form, Dropdown } from "react-bootstrap";
import { removeDuplicates } from "../../helpers/HelperMethods";
import removeAttachment from "../../resources/Icons/Icon - Remove Attachment.svg";
import { OverlayTrigger } from "react-bootstrap";
import AppContext from "../../app/AppContext";
import PropTypes from "prop-types";

const TaskAssignMembers = ({
  assignedMembers,
  assignedOwner,
  filteredAssignedMembers,
  addMembersInForm,
  removeMembersInForm,
  isDisabled,
  permissionTaskMembers = [],
  task,
  currUserId,
  ownerGroup = null,
  isDisableApprover
}) => {
  const [membersList, setMembersList] = useState([]);
  const [ownerMember, setOwnerMember] = useState({});
  const [editorMembers, setEditorMembers] = useState([]);
  const [viewerMembers, setViewerMembers] = useState([]);
  const [approverMembers, setApproverMembers] = useState([]);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState({ editor: false, viewer: false, approver: false });
  const [filteredMembersName, setFilterMembersName] = useState([]);
  const [inputField, setInputField] = useState({ editor: "", viewer: "", approver: "" });
  const [errorMessage, setErrorMessage] = useState({ editor: "", viewer: "", approver: "" });
  const assignMemberOptions = ["Editor", "Viewer", "Approver"];
  const node = useRef();
  const context = useContext(AppContext);
  // classnames can only be updated as a single variable, not inside className. Recommendation as follows
  let dropdownClassName = isDisabled ? "dropdown-members dropdown-button-disabled" : "dropdown-members";
  let ownerMembersClassName = "userInitialsCompactOwner";
  let ownerLabelClassName = "taskUserListTitle";
  let otherMembersClassName = isDisabled ? " userInitialsCompactOwnerDisabled" : "userInitialsCompactOwner";
  let crossBtnClassName = isDisabled ? "cross-button cross-button-disabled" : "pointer cross-button";
  let userPillClassName = isDisabled ? "pills-gray-background" : "pills-blue-background";
  let permissionLabelClassName = isDisabled ? "appFontSubHeadingDisabled" : "appFontSubHeading";
  
  useEffect(() => {
    if (ownerGroup && ownerGroup.GroupMembers) {
      ownerGroup.Members = ownerGroup.GroupMembers;
    }
  }, [ownerGroup])

  useEffect(() => {
    let newAssignedMembers = removeDuplicates(assignedMembers, "Id");
  
    const getAssignedMembers = permission => {
      let newPermissionTaskMembers = Array.from(new Set(permissionTaskMembers));
      let newPermissionMember = [];
      let availableMembers = [];

      if (permission === "editor") {
        newPermissionMember = [...newPermissionTaskMembers.filter(p => p.canEdit === true)];
      } else if (permission === "viewer") {
        newPermissionMember = [...newPermissionTaskMembers.filter(p => p.canView === true)];
      } else if (permission === "approver") {
        newPermissionMember = [...newPermissionTaskMembers.filter(p => p.canApprove === true)];
      }

      if (newPermissionMember.length > 0) {
        availableMembers = newAssignedMembers.filter(member => {
          return newPermissionMember.some(p => {
            return p.Id === member.Id;
          });
        });
      }
      return availableMembers;
    };

    const getUpdatedFilterMembersName = concatMembers => {
      let updateFilterMembersName = [];
      let newFilteredNames = [];

      if (concatMembers) {
        concatMembers.map(member => newFilteredNames.push(member.Name));
        updateFilterMembersName = filteredAssignedMembers.filter(name => !newFilteredNames.includes(name));
      }
      return updateFilterMembersName;
    };

    let editorArray = getAssignedMembers("editor");
    let viewerArray = getAssignedMembers("viewer");
    let approverArray = getAssignedMembers("approver");
    let removeConcatMembers = getUpdatedFilterMembersName([...editorArray, ...viewerArray, ...approverArray]);

    setMembersList([...newAssignedMembers]);

    setOwnerMember(assignedOwner);
    setFilterMembersName([...removeConcatMembers]);
    setFilteredSuggestions([...removeConcatMembers]);
    setEditorMembers([...editorArray]);
    setViewerMembers([...viewerArray]);
    setApproverMembers([...approverArray]);
  }, [assignedMembers, assignedOwner, filteredAssignedMembers]);

  const updateFilterMembersName = (permission, filterName) => {
    let newFilterNames = [];
    let resultFilterMembersName = [];

    if (permission === "editor") {
      newFilterNames = editorMembers.find(member => member.Name === filterName);
    } else if (permission === "viewer") {
      newFilterNames = viewerMembers.find(member => member.Name === filterName);
    } else if (permission === "approver") {
      newFilterNames = approverMembers.find(member => member.Name === filterName);
    }

    if (newFilterNames) {
      resultFilterMembersName = [...filteredMembersName];
      resultFilterMembersName.push(filterName);
    } else {
      resultFilterMembersName = [...filteredMembersName.filter(name => filterName !== name)];
    }

    return resultFilterMembersName;
  };

  const assignMembers = permission => {
    let newMembers = [];
    if (permission === "editor") {
      newMembers = [...editorMembers];
    } else if (permission === "viewer") {
      newMembers = [...viewerMembers];
    } else if (permission === "approver") {
      newMembers = [...approverMembers];
    }
    return newMembers;
  };

  const updateMembers = (permission, newMembers) => {
    if (permission === "editor") {
      setEditorMembers(newMembers);
    } else if (permission === "viewer") {
      setViewerMembers(newMembers);
    } else if (permission === "approver") {
      setApproverMembers(newMembers);
    }
  };

  const showUserMembers = (permission, isApproveDisable) => {
    let currMembers = assignMembers(permission);
    if(isApproveDisable){
      return currMembers.map(user => {
        return (
          <div key={user.Id}>
            <div className={`small d-flex d-flex-row pill-fill-container pills-gray-background`}>
              <span className={`userInitialsCompact userInitialsCompactOwnerDisabled`}>{user.Initials}</span>
              <span className={`w-8 pl-2 pr-1 taskUserListTitle taskUserListTitleDisabled pull-left`}>
                {user.Name.length > 20 ? `${user.Name.slice(0, 20)}...` : user.Name}
              </span>
              <img
                src={removeAttachment}
                className={`cross-button cross-button-disabled`}
                alt="cross-button"
              />
            </div>
          </div>
        );
      });
    }
    else{
      return currMembers.map(user => {
        return (
          <div key={user.Id}>
            <div className={`small d-flex d-flex-row pill-fill-container ${userPillClassName}`}>
              <span className={`userInitialsCompact ${otherMembersClassName}`}>{user.Initials}</span>
              <span className={`w-8 pl-2 pr-1 ${ownerLabelClassName} pull-left`}>
                {user.Name.length > 20 ? `${user.Name.slice(0, 20)}...` : user.Name}
              </span>
              <img
                src={removeAttachment}
                className={`${crossBtnClassName}`}
                alt="cross-button"
                onClick={() => {
                  if (!isDisabled) deleteUserMembers(permission, user.Id);
                }}
              />
            </div>
          </div>
        );
      });
    }    
  };

  const deleteUserMembers = (permission, removeId) => {
    let updatedMembers = assignMembers(permission);
    let removedMember = updatedMembers.find(member => member.Id === removeId);

    setFilterMembersName([...updateFilterMembersName(permission, removedMember.Name)]);
    setFilteredSuggestions([...updateFilterMembersName(permission, removedMember.Name)]);
    updatedMembers = updatedMembers.filter(member => member.Id !== removeId);
    removeMembersInForm(permission, removeId);
    updateMembers(permission, updatedMembers);
  };

  const onChangeMemberInput = (e, permission) => {
    let currInput = e.currentTarget.value;
    let availableSuggestions = filteredMembersName.filter(
      suggestion => suggestion.toLowerCase().indexOf(currInput.toLowerCase()) > -1
    );
    if (currInput) {
      setShowSuggestions({ ...showSuggestions, [permission]: true });
    } else {
      setShowSuggestions({ ...showSuggestions, [permission]: false });
    }
    setFilteredSuggestions(availableSuggestions);
    setErrorMessage({ ...errorMessage, [permission]: "" });
    setInputField({ ...inputField, [permission]: e.currentTarget.value });
  };

  const onKeyDownAddMembers = (e, permission) => {
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions({ ...showSuggestions, [permission]: false });
      setInputField({ ...inputField, [permission]: filteredSuggestions[activeSuggestion] });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const onClickMemberSuggestion = (e, permission) => {
    let updatedMembers = assignMembers(permission);
    // New members filter now by words due to inner text remove last space in names automatically
    let newMember = membersList.filter(
      member => member.Name.split(" ").join("") === e.currentTarget.innerText.split(" ").join("")
    );
    
    const addId = newMember[0]?.Id;
    const checkEditorExists = editorMembers.some(member => member.Name === newMember[0]?.Name);
    const checkViewerExists = viewerMembers.some(member => member.Name === newMember[0]?.Name);
    const checkApproverExists = approverMembers.some(member => member.Name === newMember[0]?.Name);

    if (checkEditorExists || checkViewerExists || checkApproverExists) {
      setErrorMessage({
        ...errorMessage,
        [permission]: `${newMember[0].Name.length > 20 ? `${newMember[0].Name.slice(0, 20)}...` : newMember[0]?.Name}
        already exists, please try a different member`
      });
    } else {
      addMembersInForm(permission, addId);
      updatedMembers = [...updatedMembers, ...newMember];
      updateMembers(permission, updatedMembers);
    }
    setActiveSuggestion(0);
    setFilteredSuggestions([...updateFilterMembersName(permission, newMember[0]?.Name)]);
    setFilterMembersName([...updateFilterMembersName(permission, newMember[0]?.Name)]);
    setShowSuggestions({ ...showSuggestions, [permission]: false });
    setInputField({ ...inputField, [permission]: "" });
  };

  const handleLostFocus = (permission, event) => {
    setShowSuggestions({ ...showSuggestions, [permission]: false });
    setInputField({ ...inputField, [permission]: "" });
    setFilteredSuggestions(filteredMembersName);
    event.preventDefault();
  };

  const handleOnFocus = (permission, event) => {
    setShowSuggestions({ ...showSuggestions, [permission]: true });
    setInputField({ ...inputField, [permission]: "" });
    event.preventDefault();
  };

  const suggestionsListComponent = option => (
  
    <Fragment>
      <div className="dropdown-main-top-level" ref={node}>
        <Dropdown className="dropdown-main" show={showSuggestions[option]}>
          {
          (option === "approver" && ((task?.Permission === "Edit" || task?.UserRole === "Edit") && task?.CreatedById != currUserId)) ?
          <Dropdown.Toggle
            tabIndex="0"
            id="dropdown-toggle"
            className={`pill-wapper dropdown-button dropdown-members dropdown-button-disabled dropdown-input assignMembersWrapper`}
            as="div"
          >
            {showUserMembers(option,true)}
            <input
              type="text"
              value={inputField[option]}
              onChange={e => onChangeMemberInput(e, option)}
              onKeyDown={e => {
                onKeyDownAddMembers(e, option);
                e.key === "Enter" && e.preventDefault();
              }}
              onBlur={e => {
                handleLostFocus(option, e);
              }}
              onFocus={e => {
                handleOnFocus(option, e);
              }}
              placeholder="Enter new member"
              disabled={true}
            />
          </Dropdown.Toggle>
          :
          
          <Dropdown.Toggle
            tabIndex="0"
            id="dropdown-toggle"
            className={`pill-wapper dropdown-button assignMembersWrapper ${(option === "approver" && isDisableApprover) ? "dropdown-members dropdown-button-disabled" : dropdownClassName} dropdown-input`}
            as="div"
          >
            {showUserMembers(option)}
            <input
              type="text"
              value={inputField[option]}
              onChange={e => onChangeMemberInput(e, option)}
              onKeyDown={e => {
                onKeyDownAddMembers(e, option);
                e.key === "Enter" && e.preventDefault();
              }}
              onBlur={e => {
                handleLostFocus(option, e);
              }}
              onFocus={e => {
                handleOnFocus(option, e);
              }}
              placeholder="Enter new member"
              disabled={isDisabled || (option === "approver" && isDisableApprover)}
            />
          </Dropdown.Toggle>
          }
          <Dropdown.Menu className={`dropdown-main-menu`} as="div">
            {filteredSuggestions.map((suggestion, index) => {
              return (
                <Fragment key={index}>
                  <Dropdown.Item
                    onKeyDown={e => onKeyDownAddMembers(e)}
                    className="dropdown-row"
                    key={1}
                    value={1}
                    name={suggestion}
                    onClick={e => onClickMemberSuggestion(e, option)}
                    onMouseDown={e => {
                      e.preventDefault();
                    }} // This code is added to prevent onBlur to trigger before onClick
                  >
                    <span className="dropdown-row-text">{suggestion}</span>
                  </Dropdown.Item>

                  {index !== filteredSuggestions.length - 1 && <Dropdown.Divider className="dropdown-row-divider" />}
                </Fragment>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        {errorMessage[option] !== "" && (
          <Form.Label>
            <div type="invalid" className="error-message-assign">
              {errorMessage[option]}
            </div>
          </Form.Label>
        )}
      </div>
    </Fragment>
  );

  return (
    <div>
      <Container className="form-custom-control">
        <Form.Group className="mt-3">
          <Form.Label className={`loginInput ${permissionLabelClassName}`}>Owner</Form.Label>
          {ownerGroup!= null 
          ? 
            <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 250 }}
            overlay={
              <div className="members-popover-container">
                <div
                  className="members-popover">
                  <ul className={`text-right members-popover-scroll`}>
                    {ownerGroup?.Members?.map(groupMembers => {
                      return (
                        <li key={groupMembers.UserId}>
                          <span
                            className={`userInitialsCompact appFont ${context?.currUser?.Id==groupMembers.UserId ? "userInitialsCompactOwner" :""} `}
                            key={groupMembers.User?.Id}
                          >
                            {groupMembers.User.Initials}
                          </span>
                          <span className="user-name">
                            {groupMembers.User.Name}
                          </span>

                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            }
          >
             <div className="d-flex d-flex-row">           
               {ownerGroup?.Initials && (
                <span className={`userInitialsCompact ${ownerMembersClassName}`}>{ownerGroup.Initials}</span>
              )}
              <span style={{ height: "2.2em" }} className={`w-8 pl-2 pr-1 pt-1 ${ownerLabelClassName} pull-left`}>
                {ownerGroup?.Name ? ownerGroup.Name : "Select a Project before assigning members"}
              </span>
            </div>
          </OverlayTrigger>
          :
          <div className="d-flex d-flex-row">           
            {ownerMember?.Initials && (
              <span className={`userInitialsCompact ${ownerMembersClassName}`}>{ownerMember.Initials}</span>
            )}
            <span style={{ height: "2.2em" }} className={`w-8 pl-2 pr-1 pt-1 ${ownerLabelClassName} pull-left`}>
              {ownerMember?.Name ? ownerMember.Name : "Select a Project before assigning members"}
            </span>
          </div>
          }
        </Form.Group>

        {ownerGroup!= null ?         
        <Form.Group className="mt-3">
          <Form.Label className={`loginInput ${permissionLabelClassName}`}>Creator</Form.Label>     
            <div className="d-flex d-flex-row">           
              {ownerMember?.Initials && (
                <span className={`userInitialsCompact userInitialsCompactCreator ${ownerMembersClassName}`}>{ownerMember.Initials}</span>
              )}
              <span style={{ height: "2.2em" }} className={`w-8 pl-2 pr-1 pt-1 ${ownerLabelClassName} pull-left`}>
                {ownerMember?.Name ? ownerMember.Name : "Select a Project before assigning members"}
              </span>
            </div>   
          </Form.Group>
           :<div></div>
        }
        {assignMemberOptions.map(option => (
          <Form.Group key={option.toLowerCase()}>
            <Form.Label className={`loginInput ${permissionLabelClassName}`}>{option}</Form.Label>
            {suggestionsListComponent(option.toLowerCase())}
          </Form.Group>
        ))}
      </Container>
    </div>
  );
};

TaskAssignMembers.propTypes = {
  assignedMembers: PropTypes.array,
  assignedOwner: PropTypes.object,
  filteredAssignedMembers: PropTypes.array,
  addMembersInForm: PropTypes.func,
  removeMembersInForm: PropTypes.func,
  isDisabled: PropTypes.bool,
  permissionTaskMembers: PropTypes.array,
  task: PropTypes.object,
  currUserId: PropTypes.number,
  ownerGroup: PropTypes.object,
  isDisableApprover: PropTypes.bool
};
export default TaskAssignMembers;
