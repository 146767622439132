import React from "react";
import PropTypes from 'prop-types';

export default function ChatSuggestion({ text, onClick }) {
  return (
    <div
      className="d-flex align-items-center chat-suggestions-block"
      onClick={onClick}
    >
      {text}
    </div>
  );
}

ChatSuggestion.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
